import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { get } from 'lodash';
import {
  status as commonStatus,
  shortDescription as commonShortDescription,
  description as commonDescription,
  name as commonName,
  dueDate as commonDueDate,
} from '../common';
import { numberInput } from 'utils/editable';
import {
  CollaboratorChooser,
  CollaboratorChooserAppendable,
  formatCollaborator,
} from 'components/choosers/CollaboratorChooser';
import { WorkContainerChooser } from 'components/choosers/WorkContainerChooser';
import { None, defaultNone, sortBy, moreTooltipper, getFilterDropdown } from '../../helpers';
import {
  WORK_ORDER_EDIT_CAMPAIGN,
  WORK_ORDER_EDIT_COLLABORATORS,
  WORK_ORDER_EDIT_DESCRIPTION,
  WORK_ORDER_EDIT_DUE_DATE,
  WORK_ORDER_EDIT_HOURS_ACT,
  WORK_ORDER_EDIT_HOURS_EST,
  WORK_ORDER_EDIT_NAME,
  WORK_ORDER_EDIT_STATUS,
  WORK_ORDER_EDIT_VENDOR_ORG,
} from 'utils/access-control/rules';
import { WORK_CAMPAIGN } from 'utils/constants';
import {
  CollaboratorsCompactIcon,
  CollaboratorsTag,
  WorkNumberContainer,
  WorkOrderNumberAndCollabIcon,
} from './workOrders.style';
import { WorkOrderNameEditor } from 'components/editorModals/WorkOrderNameEditor';
import { VendorOrgChooser } from 'components/choosers/VendorOrgChooser';
import { FeatureFlag, flags } from 'utils/features';
import { useVendorOrgFilterOptions } from 'utils/organization';

export const name = {
  ...commonName,
  onHeaderCell: () => ({
    'data-testid': 'work-orders-name-header',
  }),
  editRule: WORK_ORDER_EDIT_NAME,
  defaultFilterOperator: 'CONTAINS',
  edit(record, { onChange, ...props }) {
    const onEditWOName = name => {
      onChange({ name });
      record.name = name;
    };
    return (
      <>
        <Input
          style={{ maxWidth: '300px' }}
          value={record.name}
          onChange={
            onChange &&
            (e => {
              onChange({ name: e.target.value });
              record.name = e.target.value;
            })
          }
          {...props}
        />
        <FeatureFlag flag={flags.MAXIMO_SRS}>
          <WorkOrderNameEditor workOrder={record} onSave={onEditWOName}></WorkOrderNameEditor>
        </FeatureFlag>
      </>
    );
  },
};

//created for bulk seperately as bulk does not deal with exteral SRs
export const bulkEditName = {
  ...commonName,
  onHeaderCell: () => ({
    'data-testid': 'work-orders-name-header',
  }),
  editRule: WORK_ORDER_EDIT_NAME,
  defaultFilterOperator: 'CONTAINS',
};
export const nameBulkEdit = disableCheckbox => {
  return {
    ...bulkEditName,
    disableCheckbox: disableCheckbox,
  };
};

export const shortDescription = {
  ...commonShortDescription,
  onHeaderCell: () => ({
    'data-testid': 'work-orders-description-header',
  }),
  editRule: WORK_ORDER_EDIT_DESCRIPTION,
  defaultFilterOperator: 'CONTAINS',
};

export const shortDescriptionBulkEdit = disableCheckbox => {
  return {
    ...shortDescription,
    disableCheckbox: disableCheckbox,
  };
};

export const description = {
  ...commonDescription,
  editRule: WORK_ORDER_EDIT_DESCRIPTION,
};

export const descriptionBulkEdit = disableCheckbox => {
  return {
    ...description,
    disableCheckbox: disableCheckbox,
  };
};

export const dueDate = (releaseToggle = false) => {
  return {
    ...commonDueDate(releaseToggle),
    onHeaderCell: () => ({
      'data-testid': 'work-orders-due-date-header',
    }),
    editRule: WORK_ORDER_EDIT_DUE_DATE,
  };
};

export const dueDateBulkEdit = disableCheckbox => {
  return {
    ...dueDate(),
    disableCheckbox: disableCheckbox,
  };
};

export const status = {
  ...commonStatus,
  onHeaderCell: () => ({
    'data-testid': 'work-orders-status-header',
  }),
  editRule: WORK_ORDER_EDIT_STATUS,
};

export const statusBulkEdit = disableCheckbox => {
  return {
    ...status,
    disableCheckbox: disableCheckbox,
  };
};

export const workOrderNumber = {
  key: 'number',
  title: 'Work Order',
  filterProperty: 'number',
  onHeaderCell: () => ({
    'data-testid': 'work-orders-number-header',
  }),
  render: record => {
    const { id, number } = record || {};
    return [id, number].every(Boolean) ? (
      <Link to={`/work-orders/${id}`}> {number} </Link>
    ) : (
      <None />
    );
  },
};

export const workOrderNumberAndCollab = {
  ...workOrderNumber,
  render: record => {
    const { id, number, collaboratorUsers } = record || {};
    return [id, number].every(Boolean) ? (
      <WorkNumberContainer>
        <Link to={`/work-orders/${id}`}> {number} </Link>
        {collaboratorUsers && collaboratorUsers.length ? <WorkOrderNumberAndCollabIcon /> : null}
      </WorkNumberContainer>
    ) : (
      <None />
    );
  },
};

export const workCampaign = {
  key: 'workCampaign',
  title: 'Campaign',
  dataIndex: 'workCampaign',
  onHeaderCell: () => ({
    'data-testid': 'work-orders-campaign-header',
  }),
  sorter: sortBy('workCampaign.name'),
  filterProperty: 'workCampaign.name',
  defaultFilterOperator: 'CONTAINS',
  render: defaultNone(({ id, name }) => <Link to={`/campaigns/${id}`}>{name}</Link>),
  editRule: WORK_ORDER_EDIT_CAMPAIGN,
  edit(record, { onChange, ...props }) {
    const { workCampaign } = record;

    // WorkOrder may be unattached to WorkCampaign
    const name = workCampaign ? workCampaign.name : null;
    const id = workCampaign ? workCampaign.id : null;

    return (
      <WorkContainerChooser
        defaultValue={name ? { key: name } : undefined}
        containerId={id}
        type={WORK_CAMPAIGN}
        onChange={
          onChange &&
          (value => {
            onChange({
              workCampaignId: value,
            });
          })
        }
        {...props}
      />
    );
  },
};

// The only difference is that atlas uses the more generic key of 'parentName'
export const atlasWorkCampaign = {
  ...workCampaign,
  // NOTE: keys must match the filter defined in the tabs.ts TAB_LIST filter or the Unplanned tab will reset when advancing to the next page.
  key: 'parentName',
};

export const legacyWorkOrderVendorColumn = {
  title: 'Vendor (Legacy)',
  key: 'vendor',
  dataIndex: 'tasks',
  render: tasks => {
    if (!tasks || !tasks.length) return <None />;
    const vendors = tasks.map(task => get(task, 'vendor'));
    return moreTooltipper([...new Set(vendors)]);
  },
};

// used in the work order header
export const workOrderVendorHeaderColumn = {
  key: 'vendorOrganizationId',
  title: 'Vendor',
  dataIndex: 'vendorOrganization',
  edit(record, { onChange, ...props }) {
    return (
      <VendorOrgChooser
        defaultValue={record.vendorOrganization?.id}
        onChange={vendorOrganizationId => {
          // setting to null when undefined allows us to clear the value
          onChange({ vendorOrganizationId: vendorOrganizationId ?? null });
        }}
        {...props}
      />
    );
  },
  editRule: WORK_ORDER_EDIT_VENDOR_ORG,
  render: vendorOrganization => {
    return vendorOrganization?.name ?? <None />;
  },
};

// used in the various work order lists
export function useWorkOrderVendorOrgColumn() {
  const { vendorOptions } = useVendorOrgFilterOptions();

  return {
    ...workOrderVendorHeaderColumn,
    // we update the WO by vendor ID, but filter/sort by vendor name
    key: 'vendorName',
    filterDropdown: getFilterDropdown({
      includeNoneOption: true,
      noneOptionValue: 'IS_NULL',
      type: 'string',
      values: vendorOptions,
    }),
    filterProperty: 'vendorOrganization.name',
    filters: vendorOptions,
    onHeaderCell: () => ({ 'data-testid': 'work-order-vendor-org-header' }),
    sorter: sortBy('vendorOrganization.name'),
  };
}

export const useWorkOrderVendorOrgBulkEditColumn = (vendorOrgColumn, disableCheckbox = false) => {
  if (!vendorOrgColumn) return null;

  return {
    ...vendorOrgColumn,
    disableCheckbox,
    // updating a work order expects this key name for vendor
    key: 'vendorOrganizationId',
  };
};

export const hoursEstimated = {
  key: 'hoursEstimated',
  title: 'Hours (est)',
  dataIndex: 'hoursEstimated',
  sorter: sortBy('hoursEstimated'),
  render: defaultNone(),
  editRule: WORK_ORDER_EDIT_HOURS_EST,
  edit: numberInput('hoursEstimated'),
};

export const hoursActual = {
  key: 'hoursActual',
  title: 'Hours (act)',
  dataIndex: 'hoursActual',
  sorter: sortBy('hoursActual'),
  render: defaultNone(),
  editRule: WORK_ORDER_EDIT_HOURS_ACT,
  edit: numberInput('hoursActual'),
};

export const collaborators = {
  key: 'collaborators',
  title: 'Collaborators',
  help: 'Users can be granted narrowly-scoped access to specific work orders (and associated asset and inspection data) by selecting them here.',
  dataIndex: 'collaboratorUsers',
  getInitialValue: record => {
    return record.collaboratorUsers ? record.collaboratorUsers : [];
  },
  render: collaborators => {
    if (!collaborators || !collaborators.length) {
      return <None />;
    }

    const formattedCollaborators = collaborators.map(formatCollaborator);

    const [collab, ...moreCollabs] = formattedCollaborators;
    return (
      <Fragment>
        {moreTooltipper([
          <CollaboratorsTag key={collab}>{collab}</CollaboratorsTag>,
          ...moreCollabs,
        ])}
      </Fragment>
    );
  },
  editRule: WORK_ORDER_EDIT_COLLABORATORS,
  edit(record, { onChange, ...props }) {
    const { collaboratorUsers: value } = record || {};
    // Filter for null users, in case an invalid user is present.
    const formattedValues = (value || []).map(collaborator => collaborator?.email).filter(x => x);
    return (
      <CollaboratorChooser
        defaultValue={formattedValues}
        onChange={onChange && (collaborators => onChange({ collaborators }))}
        {...props}
      />
    );
  },
};

export const collaboratorsCompact = {
  ...collaborators,
  title: '',
  render: collaborators => {
    if (!collaborators || !collaborators.length) {
      return null;
    }
    return <CollaboratorsCompactIcon title={collaborators.map(formatCollaborator).join('\n')} />;
  },
};

export const collaboratorsAppendable = {
  ...collaborators,
  edit(record, { onChange, ...props }) {
    const { collaboratorUsers: value } = record || {};
    const formattedValues = (value || []).map(collaborator => collaborator.email);

    return (
      <CollaboratorChooserAppendable
        defaultValue={formattedValues}
        onChange={onChange && (collaborators => onChange(collaborators))}
        {...props}
      />
    );
  },
};

export const collaboratorsAppendableBulkEdit = disableCheckbox => {
  return {
    ...collaboratorsAppendable,
    disableCheckbox: disableCheckbox,
  };
};
