import { useCallback, useMemo } from 'react';
import { useAddTagMutation, useTagsChooserQuery, useRemoveTagMutation } from 'types/atlas-graphql';

export const useTags = () => {
  const { data, loading } = useTagsChooserQuery();

  const [addTag, { loading: addLoading }] = useAddTagMutation();
  const [removeTag, { loading: deleteLoading }] = useRemoveTagMutation();

  const add = useCallback(
    async (tagToAdd: string) => {
      return await addTag({
        variables: {
          tag: tagToAdd,
        },
        refetchQueries: ['TagsChooser'],
      });
    },
    [addTag]
  );

  const remove = useCallback(
    async (tagIdToRemove: string) => {
      return await removeTag({
        variables: { id: tagIdToRemove },
        refetchQueries: ['TagsChooser'],
      });
    },
    [removeTag]
  );

  return useMemo(
    () => ({
      tags: data?.tags ?? [],
      loading,
      addTag: add,
      removeTag: remove,
      addLoading,
      deleteLoading,
    }),
    [data, loading, add, remove, addLoading, deleteLoading]
  );
};
