import { Alert } from 'components/ui';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

export const StyledTitle = styled.div`
  div:last-of-type {
    margin-top: 1rem;
  }
`;
