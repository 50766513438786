import { Alert } from 'components/ui';
import styled from 'styled-components';
import COLORS from 'utils/color/definitions';

export const StyledAlert = styled(Alert)`
  background-color: ${COLORS.TEAL};
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;

  .ant-alert-message {
    color: ${COLORS.WHITE};
    text-align: center;
  }
`;
