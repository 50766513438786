import { useContext, useEffect, useMemo, useRef } from 'react';
import { Select } from 'antd5';
import { BaseSelectRef } from 'rc-select';
import { getAssetColumnCopy } from 'components/data/assets/assetColumnRenderers';
import { Button, Tooltip } from 'components/ui';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  SelectContainer,
} from 'components/data/helpers/filters.style';
import {
  AtlasGqlAsset,
  useGetAssetTypesMinimalQuery,
  useGetAssetsForAssetFilterQuery,
} from 'types/atlas-graphql';
import { filterOption } from 'components/choosers/helpers';
import { AutoFocus } from 'horizon/components/AutoFocus';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';
import { DataTableContext } from 'components/DataTable';
import { naturalSortBy } from 'utils/naturalSort';

const PAGE_SIZE = 500;

/**
 * Table column filter component for filtering assets in an asset table.
 *
 * This component takes an asset type name a props, and provides a list of existing assets of that
 * type in the current organization.
 * For non-site tables, if a site filter is applied, the values are limited to those at those at
 * the selected site(s).
 */
type SimpleAssetFilterProps = CustomFilterDropdownProps & {
  assetTypeName: string;
};

export const SimpleAssetFilter: React.FunctionComponent<SimpleAssetFilterProps> = ({
  assetTypeName,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const inputRef = useRef<BaseSelectRef>(null);
  const selectedAssetIds = selectedKeys ?? [];

  const { filteredInfo } = useContext(DataTableContext);
  const selectedSiteIds: string[] = useMemo(
    () => filteredInfo?.site ?? [],
    [JSON.stringify(filteredInfo?.site)]
  );

  const { data: typesData, loading: typesLoading } = useGetAssetTypesMinimalQuery();
  const assetTypeId: string | undefined = typesData?.assetTypes.find(
    ({ name }) => name === assetTypeName
  )?.id;

  const { data, loading, fetchMore, previousData } = useGetAssetsForAssetFilterQuery({
    variables: {
      filterBy: [
        ...(assetTypeId ? [{ key: 'assetTypeIds', values: [assetTypeId] }] : []),
        ...(selectedSiteIds.length ? [{ key: 'descendantOfId', values: selectedSiteIds }] : []),
      ],
      limit: PAGE_SIZE,
    },
    skip: !assetTypeId,
    notifyOnNetworkStatusChange: true,
  });
  const assetOptions = useMemo(
    () =>
      (data?.assetsWithMetadata?.items ?? [])
        .map((asset: AtlasGqlAsset) => ({
          id: asset.id,
          displayName: getAssetColumnCopy({
            asset,
            includeSite: selectedSiteIds?.length !== 1,
            separatorText: ' | ',
          }),
        }))
        .sort(naturalSortBy('displayName')),
    [data?.assetsWithMetadata?.items]
  );
  const siteFilteringRequired = useMemo(
    () => !selectedSiteIds.length && (data?.assetsWithMetadata.totalCount ?? 0) > 1500,
    [data?.assetsWithMetadata?.totalCount]
  );

  /**
   * Automatically fetch assets in pages of PAGE_SIZE. The next page will automatically be
   * fetched until all available assets have been returned. Once the first page has
   * loaded, the asset dropdown will be interactive.
   */
  useEffect(() => {
    if (
      data &&
      data.assetsWithMetadata.items.length !== previousData?.assetsWithMetadata.items.length &&
      !siteFilteringRequired
    ) {
      fetchMore({
        variables: {
          offset: data.assetsWithMetadata.items.length,
        },
        updateQuery: (previousData, { fetchMoreResult }) => ({
          assetsWithMetadata: {
            ...previousData.assetsWithMetadata,
            items: [
              ...previousData.assetsWithMetadata.items,
              ...fetchMoreResult.assetsWithMetadata.items,
            ],
          },
        }),
      });
    }
  }, [data, previousData, siteFilteringRequired]);

  const handleChange = (value: string[]) => {
    setSelectedKeys(value);
  };

  const handleConfirm = () => {
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    clearFilters && clearFilters();
  };

  return (
    <AutoFocus elementToFocus={inputRef}>
      <CustomFilterPopover className="ant-table-filter-dropdown">
        <CustomFilterContainer>
          <SelectContainer style={{ paddingRight: '44px', width: '240px' }}>
            <Tooltip
              title={
                siteFilteringRequired
                  ? 'Please select at least one site in the site column filter'
                  : undefined
              }
            >
              <Select
                id="asset-filter-dropdown"
                ref={inputRef}
                placeholder={'Select asset...'}
                disabled={siteFilteringRequired}
                loading={loading || typesLoading}
                allowClear={true}
                showSearch={true}
                filterOption={filterOption}
                virtual={false}
                value={selectedAssetIds}
                onChange={handleChange}
                mode={'multiple'}
                popupMatchSelectWidth={false}
              >
                {assetOptions.map(({ id, displayName }) => (
                  <Select.Option key={id} className="asset-filter-option" value={id}>
                    {displayName}
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
          </SelectContainer>
        </CustomFilterContainer>
        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button type="link" size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleConfirm}>
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    </AutoFocus>
  );
};
