import { AtlasGqlCollaboratorsFragmentFragment } from 'types/atlas-graphql';

export const formatCollaborator = (
  collaborator: Pick<AtlasGqlCollaboratorsFragmentFragment, 'email' | 'defaultOrganization'>
) => {
  // We at least need email and defaultOrganization to show a user
  if (!collaborator?.email && !collaborator?.defaultOrganization?.name) return '(Invalid User)';
  // If a user is deleted they will not have a defaultOrganization so only show email
  const orgName = collaborator.defaultOrganization?.name
    ? ` | ${collaborator.defaultOrganization.name}`
    : '';
  return `${collaborator.email}${orgName}`;
};
