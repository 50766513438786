import { useMemo } from 'react';
import { Form, FormInstance } from 'antd5';
import { AtlasGqlGlobalWorkContainerField } from 'types/atlas-graphql';
import { TaskFormInput } from '../helpers/TaskFormInput';

interface Props {
  fields: AtlasGqlGlobalWorkContainerField[];
  form: FormInstance<any>;
  isRepairOrInspect: (type: string) => boolean;
  type: string;
  uneditableFields: Record<string, React.ReactNode>;
  assetId?: string;
}

export function FormInputs({
  fields,
  form,
  isRepairOrInspect,
  type,
  uneditableFields,
  assetId,
}: Props) {
  function showRules(field: AtlasGqlGlobalWorkContainerField, type: string) {
    return !isRepairOrInspect(type) && field.name === 'Description'
      ? { rules: [{ required: true, message: 'Please enter a description' }] }
      : {};
  }

  const sortedFields = useMemo(() => {
    return [...fields].sort((valA, valB) => {
      const orderA =
        valA?.configured?.find(c => c?.associatedType?.type === type)?.displayOrder ?? 0;
      const orderB =
        valB?.configured?.find(c => c?.associatedType?.type === type)?.displayOrder ?? 0;
      return orderA - orderB;
    });
  }, [fields, type]);

  return sortedFields.map(field => (
    <Form.Item
      key={field.slug}
      label={field.slug === 'parent' ? 'Work Order' : field.name}
      name={field.slug}
      {...showRules(field, type)}
    >
      <TaskFormInput
        field={field}
        form={form}
        uneditableFields={uneditableFields}
        assetId={assetId}
      />
    </Form.Item>
  ));
}
