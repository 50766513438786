import { useCallback, useEffect } from 'react';
import { Form, FormInstance, Input, Select } from 'antd5';
import { CheckboxChangeEvent } from 'antd5/lib/checkbox';
import { useMemo, useState } from 'react';
import { useFeatures } from 'utils/features';
import { useDeliveryCustomerOrgOptions } from 'utils/organization';
import { StyledCheckboxContainer, StyledCheckBox, StyledInfoCircle } from './styles';
import { Tooltip } from 'components/ui/Tooltip';
import { Spinner } from 'components/ui/Spin';

interface IDeliveryOrganizationChooserProps {
  // String used in the enclosing `Form.Item`, used to map the value of the Select to the Form
  // Required for manually setting Form values within this component
  formKey: string;
  // FormInstance of the enclosing Form, used to manually set the corresponding field value using the value of the child Select
  form: FormInstance;
  // Optional UUID of the single asset to use to pre-filter for likely organizations
  // If not provided, no likely orgs will be queried
  assetIds?: string[];
  // Optional string for the data-testid on the child Select
  testId?: string;
  // Optional string, uuid of the pre-selected organization to pre-populate the Select with
  defaultOrgId?: string;
}

const allOrgsPlaceholder = 'Select an Organization for Inspection Delivery...';
const likelyOrgsPlaceholder = 'Select a likely Organization for Inspection Delivery...';
const nonLikelyOrgSelectedWarning =
  'This will be the first delivery for this Asset and Organization combination.';

export const DeliveryOrganizationChooser: React.FunctionComponent<
  IDeliveryOrganizationChooserProps
> = ({ testId, defaultOrgId, assetIds, form, formKey }) => {
  const { INSPECTION_DELIVERY } = useFeatures().features;
  const [selectedOrgName, setSelectedOrgName] = useState<string>('');
  const [showFullOrganizationChooser, setShowFullOrganizationChooser] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string | null>(null);

  const { deliveryCustomerOptions, likelyCustomerOptions, loading } = useDeliveryCustomerOrgOptions(
    {
      sort: true,
      skip: !INSPECTION_DELIVERY,
      assetIds,
    }
  );

  const onChange = useCallback(
    (organization: string | null) => {
      setSelectValue(organization);
      const newVal: any = {};
      newVal[formKey] = organization;
      form.setFieldsValue(newVal);
      const matchingOrg = deliveryCustomerOptions.find(customer => customer.value === organization);
      const orgName = matchingOrg?.label ? matchingOrg?.label : '';
      setSelectedOrgName(orgName);
    },
    [deliveryCustomerOptions, form, formKey]
  );

  const likelyOrgs = likelyCustomerOptions;

  const initialOrg = useMemo(() => {
    let resolvedInitialOrg;
    // If we're given a default org, that's the initial org
    if (defaultOrgId) {
      resolvedInitialOrg = defaultOrgId;
    }
    // If there's only one likely org, pre-select that as the initial org
    else if (likelyOrgs.length === 1) {
      const singleOrgId = likelyOrgs[0].value;
      // Don't set if we've already set the value
      // Removing this check causes an infinite rendering loop
      resolvedInitialOrg = singleOrgId;
    }
    return resolvedInitialOrg;
  }, [defaultOrgId, likelyOrgs]);

  useEffect(() => {
    // If we've got a defined initial value, pass that org id into the org change handler
    // This ensures that if a single org is selected, we still require the name validation before the form can be submitted
    if (initialOrg) {
      onChange(initialOrg);
    }
  }, [initialOrg, onChange]);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const newVal = e.target.checked;
    // Flip the boolean for showing the full collection
    setShowFullOrganizationChooser(newVal);
    // If the selected org is not in the current collection of orgs, clear it out
    // get name of selected org
    const orgCollection = newVal ? deliveryCustomerOptions : likelyCustomerOptions;
    const matchingOrg = orgCollection.find(customer => customer.label === selectedOrgName);
    // const orgName = matchingOrg?.label ? matchingOrg?.label : '';
    if (!matchingOrg) {
      // Update override the selected value with null
      onChange(null);
    }
  };

  const selectOptions = useMemo(() => {
    // If we're loading, we don't have orgs yet, so just return undefined
    if (loading) {
      return undefined;
    }

    // if the show all organizations box is checked show all orgs. Otherwise set options to just likely orgs
    if (showFullOrganizationChooser) {
      return deliveryCustomerOptions.map(org => {
        return { key: org.value, value: org.value, label: org.label };
      });
    } else {
      const val = deliveryCustomerOptions
        // Set the initial list to likelyOrgs PLUS the org represented by InitialOrg, even if it's not likely
        // This accounts for the edge case where the selected org isn't likely displaying the Org Id instead of the org Name in the selector
        .filter(org => likelyOrgs.map(o => o.value).includes(org.value) || org.value === initialOrg)
        .map(org => {
          return { key: org.value, value: org.value, label: org.label };
        });
      return val;
    }
  }, [deliveryCustomerOptions, initialOrg, likelyOrgs, loading, showFullOrganizationChooser]);

  const validateStatus = useMemo(() => {
    if (
      selectedOrgName !== '' &&
      !likelyOrgs.find(customer => customer.label === selectedOrgName)
    ) {
      return 'warning';
    }
    return '';
  }, [likelyOrgs, selectedOrgName]);

  // Return a spinner while we load so that the Select doesn't get into a weird state where it displays a known Org as a UUID instead of the name
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Form.Item
        validateStatus={validateStatus}
        help={validateStatus === 'warning' ? nonLikelyOrgSelectedWarning : ''}
      >
        <Select
          data-testid={testId}
          value={selectValue}
          id="trusted-organization-chooser"
          placeholder={showFullOrganizationChooser ? allOrgsPlaceholder : likelyOrgsPlaceholder}
          allowClear
          showSearch
          defaultValue={initialOrg}
          optionFilterProp="label"
          onChange={onChange}
          options={selectOptions}
        />
        <StyledCheckboxContainer>
          <span>
            <span>Display All Organizations</span>
            <Tooltip
              title={
                'Not seeing the organization you need? We’re showing only organizations you’ve worked with before. Select the checkbox to display all organizations.'
              }
            >
              <StyledInfoCircle />
            </Tooltip>
          </span>
          <StyledCheckBox data-testid="all-orgs-checkbox" onChange={handleCheckboxChange} />
        </StyledCheckboxContainer>
      </Form.Item>
      {selectedOrgName && (
        <Form.Item
          label={'Please enter the Organization name to confirm'}
          rules={[
            { required: true, message: 'Please enter the organization name.' },
            () => ({
              validator(_, value) {
                // Cast both selected org name and typed org name to lowercase to allow for case insensitive comparison
                if (!value || selectedOrgName.toLowerCase() === value.toLowerCase()) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The name entered does not match the selected organization')
                );
              },
            }),
          ]}
          name={'safety-check'}
          id="safety-check"
        >
          <Input
            data-testid={'safety-check-field'}
            name={'safety-check'}
            placeholder={selectedOrgName}
            type={'text'}
            allowClear={true}
            // Disable pasting text to force the user to type the whole name
            onPaste={e => {
              e.preventDefault();
              return false;
            }}
          />
        </Form.Item>
      )}
    </>
  );
};
