import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Select, Progress } from 'antd5';
import { DownOutlined, FormatPainterFilled, UpOutlined } from '@ant-design/icons';
import { groupBy, isEqual } from 'lodash';
import { DataTableContext } from 'components/DataTable';
import { getFilterBy } from 'components/DataTable/paging';
import { OpenButtonContainer, PlotContainer, PlotGrid, PlotHeader } from './BladeDamagePlot.style';
import {
  AtlasGqlBlade,
  AtlasGqlGetHorizonDamagesForPlotQueryVariables,
  AtlasGqlHorizonDamage,
  useGetBladeShapeQuery,
  useGetBladeWithShapeQuery,
  useGetHorizonDamagesForPlotQuery,
  useGetPropagationsForPlotQuery,
} from 'types/atlas-graphql';
import { useIsInView } from 'utils/hooks';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { BladeDamagePlot } from './BladeDamagePlot';
import COLORS from 'utils/color/definitions';
import { Button, Tooltip } from 'components/ui';
import { PlotStyleOptionsModal } from './PlotStyleOptionsModal/PlotStyleOptionsModal';
import {
  ColorCodingMap,
  getColorCodingMap,
  propagationTypeColorCodingMap,
  severityColorCodingMap,
} from './pointUtils';
import { ColorCodingLegend } from './ColorCodingLegend';
import { AreaSelectionIcon } from './AreaSelectionIcon';
import { UndoAreaSelectionIcon } from './UndoAreaSelectionIcon';
import { useAccountContext } from 'utils/account/AccountContext';
import { DAMAGE_PLOT_NOTIFICATION } from 'utils/release-toggles';

const FIRST_PAGE_SIZE = 100;
const PAGE_SIZE = 1500;

export enum BladeSide {
  LeadingEdge = 'Leading Edge',
  PressureSide = 'Pressure Side',
  SuctionSide = 'Suction Side',
  TrailingEdge = 'Trailing Edge',
}
const BLADE_SIDES = Object.values(BladeSide);

export enum ColorCodingAttribute {
  Severity = 'Severity',
  Type = 'Type',
  OpSeverity = 'OpSeverity',
  Subtype = 'Subtype',
  Material = 'Material',
  PropagationType = 'Propagation Type',
}
export type PlotStyleState = {
  modalOpen: boolean;
  colorCodingAttribute: ColorCodingAttribute | null;
  colorCodingAttributeValues?: string[];
};
const plotStyleStateReducer = (
  prevState: PlotStyleState,
  incomingState: PlotStyleState
): PlotStyleState => ({ ...prevState, ...incomingState });

type BladeDamagePlotContainerProps = {
  isOpen: boolean;
  plotEnabled: boolean;
  onToggle: () => void;
  columns: TTableColumnDef[];
  schemaId?: string;
  assetId?: string;
  make?: string;
  model?: string;
  demoColorCoding?: boolean;
};

export const BladeDamagePlotContainer: React.FunctionComponent<BladeDamagePlotContainerProps> = ({
  isOpen,
  plotEnabled,
  onToggle,
  columns,
  schemaId,
  assetId,
  make,
  model,
  demoColorCoding,
}) => {
  /**
   * Set of refs for unsized divs in the scroll container (see returned jsx) used to determine whether
   * each edge of the container is in view. If an edge is not visible (i.e. the container is below the
   * minimum width and scrolled elsewhere), this is used to add box-shadow in the styled-component to
   * make it clear to the user that there is more to see.
   */
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const isScrolledToTop: boolean = useIsInView(topRef);
  const isScrolledToBottom: boolean = useIsInView(bottomRef);
  const isScrolledToLeft: boolean = useIsInView(leftRef);
  const isScrolledToRight: boolean = useIsInView(rightRef);

  const filtersRef = useRef<string>();

  const { hasReleaseToggle } = useAccountContext();
  const hasDamagePlotNotificationReleaseToggle = hasReleaseToggle(DAMAGE_PLOT_NOTIFICATION);

  const [selectedBladeSides, setSelectedBladeSides] = useState<BladeSide[]>(BLADE_SIDES);
  const [allowedSelectionBladeSides, setAllowedSelectionBladeSides] = useState<BladeSide[]>([]);
  const [plotStyleState, dispatchPlotStyleState] = useReducer<
    (prevState: PlotStyleState, incomingState: Partial<PlotStyleState>) => PlotStyleState
  >(plotStyleStateReducer, {
    modalOpen: false,
    colorCodingAttribute: null,
  });
  const { colorCodingAttribute, colorCodingAttributeValues } = plotStyleState;
  const colorCodingEnabled = !!colorCodingAttribute;

  const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false);
  useEffect(() => {
    if (isOpen && !hasBeenOpened) {
      setHasBeenOpened(true);
    }
  }, [isOpen, hasBeenOpened]);

  // Turn on color coding for demo; this can be removed once we're done with 'rtv1:damage_plot_notification'
  useEffect(() => {
    if (
      hasDamagePlotNotificationReleaseToggle &&
      demoColorCoding &&
      !plotStyleState.colorCodingAttribute
    ) {
      dispatchPlotStyleState({
        colorCodingAttribute: ColorCodingAttribute.Severity,
      });
    }
  }, [demoColorCoding]);

  // Pull filters from damage table context for querying the same damages here
  const { filteredInfo, updateFilteredInfo } = useContext(DataTableContext);
  const filterBy = useMemo(
    () =>
      getFilterBy({
        filters: {
          ...filteredInfo,
          ...(schemaId ? { schemaId: [schemaId] } : {}),
          ...(assetId ? { assetId: [assetId] } : {}),
        },
        columns,
      }).filter(
        (
          f
        ): f is {
          key: any;
          values: any;
          operator: any;
        } => !!f
      ),
    [filteredInfo, columns, assetId]
  );

  /**
   * Store a stringified version of the current filters in a ref (used to prevent
   * conflicts with long-running queries of paginated damage data)
   */
  useEffect(() => {
    filtersRef.current = JSON.stringify(filterBy);
  }, [JSON.stringify(filterBy)]);

  /**
   * Get blade shape data by make/model, if provided. Otherwise, get blade shape
   * data as a child of the asset with id props.assetId. This will fall back on
   * empty string make/model, which will return the generic shape.
   *
   * Only one of these two queries will run
   */
  const { data: bladeShapeData } = useGetBladeShapeQuery({
    variables: { input: { make: make ?? '', model: model ?? '' } },
    skip: !!assetId && !make && !model,
  });
  const { data: assetBladeShapeData } = useGetBladeWithShapeQuery({
    variables: { id: assetId },
    skip: !assetId || (!!make && !!model),
  });

  // Fetch first PAGE_SIZE damages; see useEffect below for automatic pagination
  const { data, fetchMore, previousData } = useGetHorizonDamagesForPlotQuery({
    variables: {
      input: {},
      filterBy,
      limit: FIRST_PAGE_SIZE,
      offset: 0,
    },
    skip: !isOpen,
  });

  /**
   * Automatically fetch damages in pages of PAGE_SIZE. Then render data as it is loaded,
   * reducing the time until the graph becomes usable.
   */
  useEffect(() => {
    if (
      data?.getHorizonDamages?.items &&
      (data.getHorizonDamages.items.length !== previousData?.getHorizonDamages?.items?.length ||
        data.getHorizonDamages.totalCount !== previousData?.getHorizonDamages?.totalCount)
    ) {
      fetchMore({
        variables: {
          limit: PAGE_SIZE,
          offset: data.getHorizonDamages.items.length,
        },
        updateQuery: (previousData, { fetchMoreResult, variables }) => {
          /**
           * If the returned data is from a query that used the same filters as those currently
           * applied to the plot/table, the result is valid and the cache is updated.
           *
           * If filters change while this query is in flight, the below check will fail and the
           * cache for the main query will not be updated.
           *
           * This prevents issues with in flight queries with stale filters incorrectly overwriting
           * the main query results, which can cause cache conflicts and incorrectly rendered or missing
           * damages.
           */
          const filtersMatch =
            JSON.stringify(
              (variables as AtlasGqlGetHorizonDamagesForPlotQueryVariables).filterBy
            ) === filtersRef.current;

          if (filtersMatch) {
            return {
              getHorizonDamages: {
                items: [
                  ...(previousData.getHorizonDamages?.items ?? []),
                  ...(fetchMoreResult.getHorizonDamages?.items ?? []),
                ],
                totalCount: previousData.getHorizonDamages?.totalCount,
              },
            };
          } else {
            return data;
          }
        },
      });
    }
  }, [data, fetchMore, previousData]);

  const [loadingPercent, loading]: [number, boolean] = useMemo(() => {
    const loadingPercent =
      100 *
      (data?.getHorizonDamages?.totalCount === 0
        ? 1
        : !!data?.getHorizonDamages?.totalCount
          ? (data?.getHorizonDamages?.items?.length ?? 0) / data.getHorizonDamages.totalCount
          : 0);
    return [loadingPercent, loadingPercent < 100];
  }, [data?.getHorizonDamages]);

  const { data: propagationData, loading: propagationsLoading } = useGetPropagationsForPlotQuery({
    variables: {
      input: {
        damageIds: data?.getHorizonDamages?.items?.map(d => d?.id) ?? [],
      },
    },
    skip:
      !data?.getHorizonDamages?.items?.length ||
      loadingPercent < 100 ||
      colorCodingAttribute !== ColorCodingAttribute.PropagationType,
  });

  const damagesByBladeSide: { [Property in BladeSide]: AtlasGqlHorizonDamage[] } = useMemo(
    () =>
      groupBy(data?.getHorizonDamages?.items, 'primaryObservationGroupAttrs["Blade Side"]') as {
        [Property in BladeSide]: AtlasGqlHorizonDamage[];
      },
    [data?.getHorizonDamages?.items]
  );

  const colorCodingMap: ColorCodingMap | undefined = useMemo(() => {
    if (
      colorCodingEnabled &&
      ![ColorCodingAttribute.Severity, ColorCodingAttribute.PropagationType].includes(
        colorCodingAttribute
      ) &&
      colorCodingAttributeValues
    ) {
      return Object.fromEntries(
        Object.entries(getColorCodingMap(colorCodingAttributeValues)).map(
          ([attributeValue, { symbol }]) => [
            attributeValue,
            {
              symbol,
              damageCount: data?.getHorizonDamages?.items?.filter(
                d => d?.primaryObservationGroupAttrs[colorCodingAttribute] === attributeValue
              ).length,
            },
          ]
        )
      );
    }
    if (colorCodingEnabled && colorCodingAttribute === ColorCodingAttribute.Severity) {
      return Object.fromEntries(
        Object.entries(severityColorCodingMap).map(([severity, { symbol }]) => [
          severity,
          {
            symbol,
            damageCount: data?.getHorizonDamages?.items?.filter(d =>
              ['4', '5'].includes(severity)
                ? d?.primaryObservationGroupAttrs[colorCodingAttribute] === Number(severity)
                : severity === '1-3'
                  ? 1 <= d?.primaryObservationGroupAttrs[colorCodingAttribute] &&
                    d?.primaryObservationGroupAttrs[colorCodingAttribute] <= 3
                  : false
            ).length,
          },
        ])
      );
    }
    if (colorCodingEnabled && colorCodingAttribute === ColorCodingAttribute.PropagationType) {
      // call lazy query to fetch propagations here
      // figure out necessary pagination, merging, etc.

      return Object.fromEntries(
        Object.entries(propagationTypeColorCodingMap).map(([propagationType, { symbol }]) => [
          propagationType,
          {
            symbol,
            damageCount: data?.getHorizonDamages?.items?.filter(d => {
              const primaryGroupPropagationType =
                propagationData?.getPrimaryGroupPropagationsForDamages?.find(
                  p => p?.damageId === d?.id
                )?.type;
              return primaryGroupPropagationType === propagationType;
            }).length,
          },
        ])
      );
    }
    return undefined;
  }, [
    data?.getHorizonDamages?.items,
    propagationData?.getPrimaryGroupPropagationsForDamages,
    colorCodingEnabled,
    colorCodingAttribute,
  ]);

  const { bladeShapeWidths, bladeLength }: { bladeShapeWidths: number[]; bladeLength: number } =
    useMemo(
      () => ({
        bladeShapeWidths:
          bladeShapeData?.genericShapeData?.crossSectionWidths ??
          assetBladeShapeData?.genericShapeData?.crossSectionWidths ??
          [],
        bladeLength:
          bladeShapeData?.makeModelData?.length ??
          ((assetBladeShapeData?.assetWithMetadata as AtlasGqlBlade)?.bladeMakeModel
            ?.length as number),
      }),
      [bladeShapeData, assetBladeShapeData]
    );

  // When an area is selected on a plot, sets the blade side, distance, and chord filters on the table and plot accordingly
  const handleAreaSelection = useCallback(
    ({
      bladeSide,
      distanceRange,
      chordRange,
    }: {
      bladeSide: BladeSide | null;
      distanceRange: [number, number] | null;
      chordRange?: [number, number] | null;
    }) => {
      setAllowedSelectionBladeSides(bladeSide ? [bladeSide] : []);
      updateFilteredInfo({
        ...filteredInfo,
        'Blade Side': bladeSide ? [bladeSide] : null,
        Distance: distanceRange ? [distanceRange] : null,
        Chord: chordRange ? [chordRange] : null,
      });
    },
    [filteredInfo, updateFilteredInfo]
  );

  const areaSelected = allowedSelectionBladeSides.length === 1;

  // TODO: add loading indicator when fetching propagations

  return (
    <>
      {plotEnabled && hasBeenOpened && (
        <OpenButtonContainer>
          <Button
            icon={isOpen ? <DownOutlined /> : <UpOutlined />}
            onClick={onToggle}
            title={`${isOpen ? 'Hide' : 'Show'} plot`}
          />
        </OpenButtonContainer>
      )}
      <PlotContainer
        className={isOpen ? 'open' : ''}
        $isScrolledToBottom={isScrolledToBottom}
        $isScrolledToLeft={isScrolledToLeft}
        $isScrolledToRight={isScrolledToRight}
      >
        <PlotHeader $isScrolledToTop={isScrolledToTop}>
          <div>
            <span>Blade Side:</span>
            <Select
              options={BLADE_SIDES.map(side => ({
                value: side,
                disabled: isEqual(selectedBladeSides, [side]),
              }))}
              value={selectedBladeSides}
              onChange={sides =>
                setSelectedBladeSides(BLADE_SIDES.filter(side => sides.includes(side)))
              }
              showSearch={true}
              mode="multiple"
            />
            {(loading || propagationsLoading) && (
              <Tooltip
                title={`${
                  data?.getHorizonDamages?.totalCount && loading
                    ? `${data?.getHorizonDamages?.items?.length ?? 0}/${data.getHorizonDamages.totalCount} damages loaded`
                    : ''
                }${colorCodingAttribute === ColorCodingAttribute.PropagationType ? `${loading ? ', ' : ''}Propagation types loading` : ''}`}
              >
                <Progress
                  style={{
                    cursor: 'pointer',
                    marginLeft: '1rem',
                    width: '200px',
                  }}
                  strokeColor={COLORS.TEAL}
                  percent={loading ? loadingPercent : 80}
                  showInfo={false}
                />
              </Tooltip>
            )}
          </div>

          <div>
            <Button
              icon={areaSelected ? <UndoAreaSelectionIcon /> : <AreaSelectionIcon />}
              onClick={() => {
                if (areaSelected) {
                  handleAreaSelection({
                    bladeSide: null,
                    distanceRange: null,
                    chordRange: null,
                  });
                } else {
                  setAllowedSelectionBladeSides(BLADE_SIDES);
                }
              }}
              title="Select an area to filter by"
            />
            <Button
              className={colorCodingEnabled ? 'active' : ''}
              icon={<FormatPainterFilled />}
              onClick={() => dispatchPlotStyleState({ modalOpen: true })}
              title="Color code damages by attribute"
            />
          </div>
        </PlotHeader>
        <div ref={topRef} />
        <PlotGrid $isScrolledToLeft={isScrolledToLeft} $isScrolledToRight={isScrolledToRight}>
          <div ref={leftRef} />
          <div>
            {plotEnabled &&
              BLADE_SIDES.map(bladeSide => (
                <BladeDamagePlot
                  key={bladeSide}
                  visible={selectedBladeSides.includes(bladeSide)}
                  bladeSide={bladeSide}
                  damages={damagesByBladeSide[bladeSide] as AtlasGqlHorizonDamage[]}
                  propagations={propagationData?.getPrimaryGroupPropagationsForDamages ?? []}
                  damageColumns={columns}
                  loading={loading}
                  shapeWidths={bladeShapeWidths}
                  bladeLength={bladeLength}
                  colorCodingAttribute={colorCodingEnabled ? colorCodingAttribute : undefined}
                  colorCodingMap={colorCodingMap}
                  allowAreaSelection={allowedSelectionBladeSides.includes(bladeSide)}
                  onAreaSelection={handleAreaSelection}
                />
              ))}
          </div>
          <div ref={rightRef} />
          {colorCodingEnabled && colorCodingMap && (
            <ColorCodingLegend
              colorCodingAttribute={colorCodingAttribute}
              colorCodingMap={colorCodingMap}
              totalDamageCount={data?.getHorizonDamages?.items?.length ?? 0}
            />
          )}
        </PlotGrid>
        <div ref={bottomRef} />
      </PlotContainer>
      <PlotStyleOptionsModal
        plotStyleState={plotStyleState}
        dispatchPlotStyleState={dispatchPlotStyleState}
        schemaId={schemaId}
      />
    </>
  );
};
