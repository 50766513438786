import { Input, Select, InputNumber, TimePicker, FormInstance, Form } from 'antd5';
import TagChooser from 'components/choosers/TagChooser';
import { WorkContainerChooser } from 'components/choosers/WorkContainerChooser';
import { DeliveryOrganizationChooser } from 'components/choosers/DeliveryOrganizationChooser';
import { startCase } from 'utils/format';
import { TASK_STATUSES, WORK_ORDER } from 'utils/constants';
import { UTCDatePicker } from 'components/UTCDatePicker';
import {
  AtlasGqlGlobalWorkContainerField,
  AtlasGqlWorkContainerFieldType,
} from 'types/atlas-graphql';

interface Props {
  field: AtlasGqlGlobalWorkContainerField;
  form: FormInstance<any>;
  uneditableFields: Record<string, React.ReactNode>;
  assetId?: string;
}

export function TaskFormInput({ field, form, uneditableFields, assetId }: Props) {
  const testId = `task-input-${field.slug}`;

  if (field.slug === 'tags') {
    return (
      <TagChooser
        data-testid={testId}
        onChange={(tags: string[]) => form.setFieldsValue({ tags })}
      />
    );
  }

  if (field.slug === 'status') {
    return (
      <Select data-testid={testId} onChange={status => form.setFieldsValue({ status })}>
        {Object.values(TASK_STATUSES).map(status => (
          <Select.Option key={status} value={status}>
            {startCase(status)}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (field.slug === 'parent') {
    return (
      <WorkContainerChooser
        containerId={null}
        type={WORK_ORDER}
        labelInValue={false}
        onChange={(parent: string | null) => {
          form.setFieldsValue({ parent });
        }}
        testId={testId}
      />
    );
  }

  if (field.slug === 'for_organization') {
    return (
      <DeliveryOrganizationChooser
        formKey={field.slug}
        form={form}
        assetIds={assetId ? [assetId] : []}
        testId={testId}
      />
    );
  }

  const disabled = Boolean(uneditableFields[field.slug]);
  let input: React.ReactNode;

  if (field.type === AtlasGqlWorkContainerFieldType.Text) {
    input = <Input data-testid={testId} disabled={disabled} />;
  }
  if (field.type === AtlasGqlWorkContainerFieldType.Number) {
    input = <InputNumber data-testid={testId} />;
  }
  if (field.type === AtlasGqlWorkContainerFieldType.Date) {
    input = <UTCDatePicker data-testid={testId} disabled={disabled} />;
  }
  if (field.type === AtlasGqlWorkContainerFieldType.Select) {
    input = (
      <Select allowClear={true} data-testid={testId}>
        {field.options?.map((option: string) => {
          return (
            <Select.Option value={option} key={option}>
              {option}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
  if (field.type === AtlasGqlWorkContainerFieldType.TimeRange) {
    input = <TimePicker.RangePicker data-testid={testId} format="HH:mm" />;
  }

  if (input) {
    // we must wrap the input in a Form.Item with a name and noStyle since it is
    // not a direct child of Form.Item as defined in FormInputs.tsx
    return (
      <Form.Item name={field.slug} noStyle={true}>
        {input}
      </Form.Item>
    );
  }

  return null;
}
