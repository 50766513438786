import { useState, useMemo, useEffect } from 'react';
import { Form } from 'antd5';
import { StyledModal, SpinContainer } from './DetailedTaskForm.style';
import { Button, Spin, useMessage } from 'components/ui';
import { TaskAssetPicker } from 'components/choosers';
import { FormInputs } from './FormInputs';
import moment from 'moment';
import { useStandardFields } from 'utils/useStandardFields';
import { sortFields, descriptionField, forOrganizationField } from './helpers';
import {
  getRefetchQueriesForTaskCreation,
  messageContent,
  getTaskTargetInput,
} from 'components/CreateTask/helpers';
import { useFeatures } from 'utils/features';
import {
  OTHER_TASK,
  INTERNAL_BLADE_INSPECTION_TASK,
  TASK_TYPE_INTERNAL_BLADE_INSPECTION,
  REPAIR_TASK,
  INSPECT_TASK,
  INSPECTION_TASK,
} from 'utils/constants';
import { useCreateTaskMutation, useGetWorkContainerFieldsQuery } from 'types/atlas-graphql';
import { useAuthContext } from 'utils/auth';

const typeDict = {
  [OTHER_TASK]: {
    title: 'Other',
    type: 'OTHER',
  },
  [REPAIR_TASK]: {
    title: 'Repair',
    type: 'REPAIR',
  },
  [INTERNAL_BLADE_INSPECTION_TASK]: {
    title: 'Internal Blade Inspection',
    type: TASK_TYPE_INTERNAL_BLADE_INSPECTION,
  },
  [INSPECT_TASK]: {
    title: 'Inspect',
    type: 'INSPECT',
  },
  [INSPECTION_TASK]: {
    title: 'Inspection',
    type: 'INSPECTION',
  },
};

function isRepairOrInspect(type) {
  if (type === REPAIR_TASK || type === INSPECT_TASK) return true;
  return false;
}

function isInspection(type) {
  if (type === INSPECTION_TASK) return true;
  return false;
}

export function DetailedTaskForm({
  title,
  visible,
  handleClose,
  parentId,
  type,
  forOrganization = null,
  damageBranchId,
  horizonDamageId,
  assetId = '',
  queriesToRefetch = [],
  disabled = false,
  inspectionId,
}) {
  const message = useMessage();
  const { user } = useAuthContext();
  const { INSPECTION_DELIVERY } = useFeatures().features;
  const [form] = Form.useForm();
  const [creatingTask, setCreatingTask] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(undefined);
  const [initialAssetId, setInitialAssetId] = useState(assetId);
  const { CREATE_OTHER_SITE_TASK } = useFeatures().features;

  const { data, loading } = useGetWorkContainerFieldsQuery({ variables: { type } });

  const workContainerFields =
    data?.workContainerFields?.filter(field => {
      // vendor org is only available on work orders
      if (field.slug === 'vendor_organization' && field.standard) return false;
      return true;
    }) ?? [];

  const queryRefetch = getRefetchQueriesForTaskCreation({ horizonDamageId, damageBranchId });

  const [createTask] = useCreateTaskMutation({
    onCompleted({ createTask: { id, number, __typename } }) {
      message.success(messageContent({ id, number: number ?? 0, type: __typename ?? 'Task' }));

      setCreatingTask(false);
      form.resetFields();
      handleClose({ id, number: number ?? 0, type: __typename ?? 'Task' });
    },
    onError: () => {
      message.error('There was a problem creating the Task.');
      setCreatingTask(false);
    },
    refetchQueries: [...queryRefetch, ...queriesToRefetch],
  });

  const standardFields = useStandardFields();

  // Do not show Parent when opening from WO Detail page
  const filteredFields = isRepairOrInspect(type)
    ? workContainerFields
    : workContainerFields.filter(({ slug }) => slug !== 'parent');

  const additionalFields = useMemo(() => {
    return isInspection(type) && INSPECTION_DELIVERY
      ? [descriptionField, forOrganizationField]
      : [descriptionField];
  }, [INSPECTION_DELIVERY, type]);

  const allFields = useMemo(
    () => [...additionalFields, ...filteredFields],
    [additionalFields, filteredFields]
  );

  const uneditableFields = useMemo(
    () => ({
      created: moment(),
      created_by: user?.email,
      task_type: typeDict[type]?.title,
    }),
    [type, user]
  );

  // This useEffect is responsible for setting initial values of the form.
  // We have to set the initial assetId this way so that when we set the asset pickers it
  // will have an asset as a form value.
  useEffect(() => {
    const initValues = {};
    allFields.forEach(field => {
      if (uneditableFields[field.slug]) {
        initValues[field.slug] = uneditableFields[field.slug];
      }
    });
    if (initialAssetId) {
      initValues['assetId'] = initialAssetId;
    }

    // The form can't have its fields set until its rendered. Rendering happens based on visibility.
    if (form && visible) {
      form.setFieldsValue(initValues);
    }
  }, [allFields, form, initialAssetId, uneditableFields, visible]);

  const getValueFromAssetChooser = assetInfo => {
    const { assetId, isLocation, isBlade } = assetInfo;

    if (!(CREATE_OTHER_SITE_TASK && type === OTHER_TASK) && isLocation) {
      setSelectedAssetId(undefined);
      return undefined;
    }

    if (type === INTERNAL_BLADE_INSPECTION_TASK && !isBlade) {
      setSelectedAssetId(undefined);
      return undefined;
    }

    setSelectedAssetId(assetId);
    return assetId;
  };

  async function handleCreateTask() {
    setCreatingTask(true);
    const values = form.getFieldsValue();
    const formattedValues = sortFields({
      formValues: values,
      allFields,
      standardFieldKeys: {
        ...standardFields[type],
        parent: { dataIndex: 'workOrderId' },
        description: { dataIndex: 'description' },
        for_organization: { dataIndex: 'forOrganization' },
      },
      uneditableFields,
    });

    const input = isRepairOrInspect(type)
      ? {
          damageId: parentId,
          damageBranchId,
          ...(horizonDamageId ? { horizonDamageId } : {}),
          assetId: initialAssetId,
        }
      : {
          workOrderId: parentId,
          assetId: selectedAssetId ? selectedAssetId : initialAssetId,
        };

    await createTask({
      variables: {
        input: {
          type,
          ...(forOrganization && { forOrganization }),
          additionalFields: Object.entries(formattedValues.custom).map(([key, value]) => ({
            fieldId: key,
            ...value,
          })),
          ...formattedValues.standard,
          ...input,
          ...(inspectionId || horizonDamageId
            ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
            : {}),
        },
      },
    });
  }

  async function validateFields() {
    try {
      await form.validateFields();
      handleCreateTask();
    } catch (err) {
      // err
    }
  }

  const getErrorMessage = () => {
    if (type === INTERNAL_BLADE_INSPECTION_TASK) return 'Please choose a blade';
    return CREATE_OTHER_SITE_TASK && type === OTHER_TASK
      ? 'Please choose a site'
      : 'Please choose an asset';
  };

  return (
    <StyledModal
      title={title}
      className="testid-create-task-modal"
      visible={visible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <>
          <Button _version={4} data-testid="cancel-btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            _version={4}
            type="primary"
            loading={creatingTask}
            onClick={validateFields}
            data-testid="save-btn"
          >
            Save
          </Button>
        </>
      }
    >
      <Form layout="vertical" form={form}>
        {loading ? (
          <SpinContainer>
            <Spin _version={4} />
          </SpinContainer>
        ) : (
          <>
            {!isRepairOrInspect(type) && (parentId || initialAssetId) && (
              <Form.Item
                name="assetId"
                label="Asset"
                getValueFromEvent={getValueFromAssetChooser}
                rules={[
                  {
                    required: true,
                    message: getErrorMessage(),
                  },
                ]}
              >
                <TaskAssetPicker
                  disableAll={disabled}
                  taskId={parentId}
                  selectedAssetId={selectedAssetId}
                  initialAssetId={initialAssetId}
                  setInitialAssetId={setInitialAssetId}
                  showLoadingIndicator={true}
                  onChange={getValueFromAssetChooser}
                  defaults={{
                    settings: {
                      location: { multiple: false },
                      turbine: { multiple: false },
                      blade: { multiple: false, visible: true },
                    },
                    block: true,
                  }}
                />
              </Form.Item>
            )}
            <FormInputs
              fields={allFields}
              form={form}
              isRepairOrInspect={isRepairOrInspect}
              type={type}
              uneditableFields={uneditableFields}
              assetId={selectedAssetId}
            />
          </>
        )}
      </Form>
    </StyledModal>
  );
}
