import { WorkOrder } from './types';
import { AtlasGqlTaskStatus } from 'types/atlas-graphql';

export const workOrderId = 'd00d';

export const workOrder: WorkOrder = {
  id: workOrderId,
  number: 10352123,
  status: AtlasGqlTaskStatus.Pending,
  dueDate: null,
  description: 'some text',
  name: 'gonna balete dis',
  created: '2020-03-05T20:23:26.546Z',
  createdBy: {
    id: 'cb24d816-2ec3-4199-b4c5-8ea151ad3bcc',
    email: 'adam.beck@skyspecs.com',
    __typename: 'User',
  },
  tasks: [],
  additionalFields: [],
  hoursEstimated: null,
  hoursActual: null,
  collaboratorUsers: null,
  workCampaign: null,
  logSchema: [],
  __typename: 'WorkOrder',
};

export const workOrderArchived: WorkOrder = {
  id: workOrderId,
  number: 10352123,
  status: AtlasGqlTaskStatus.Pending,
  dueDate: null,
  description: 'some text',
  name: 'gonna balete dis',
  created: '2020-03-05T20:23:26.546Z',
  createdBy: {
    id: 'cb24d816-2ec3-4199-b4c5-8ea151ad3bcc',
    email: 'adam.beck@skyspecs.com',
    __typename: 'User',
  },
  tasks: [],
  additionalFields: [],
  hoursEstimated: null,
  hoursActual: null,
  collaboratorUsers: null,
  workCampaign: null,
  logSchema: [],
  archived: true,
  __typename: 'WorkOrder',
};
