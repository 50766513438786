import { FC, useState } from 'react';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { Select, ConfigProvider } from 'antd5';
import { CollaboratorChooser, CollaboratorChooserProps } from './CollaboratorChooser';

interface CollaboratorChooserAppendableProps extends Omit<CollaboratorChooserProps, 'onChange'> {
  onChange: (params: {
    collaborators: string[];
    collaboratorUpdateAction: string | undefined;
  }) => void;
}

export const CollaboratorChooserAppendable: FC<CollaboratorChooserAppendableProps> = ({
  value,
  defaultValue,
  ...props
}) => {
  const [collaboratorUpdateAction, setCollaboratorUpdateAction] = useState<string>();
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const { disabled, onChange } = props;

  const actionOptions = [
    {
      label: 'Add new collaborators',
      value: 'APPEND',
    },
    {
      label: 'Replace current collaborators',
      value: 'REPLACE',
    },
    {
      label: 'Remove current collaborators',
      value: 'REMOVE',
    },
  ];

  const emptyRender = () => (
    <div>
      <UsergroupAddOutlined style={{ marginRight: '4px' }} />
      <span>No current collaborators</span>
    </div>
  );

  const handleWideChange = (
    collaboratorUpdateAction: string | undefined,
    collaborators: string[]
  ) => {
    onChange && onChange({ collaborators, collaboratorUpdateAction });
  };

  const handleActionChange = (action: string) => {
    setCollaboratorUpdateAction(action);
    handleWideChange(action, collaborators);
  };

  const handleCollaboratorChange = (collaborators: string[]) => {
    setCollaborators(collaborators);
    handleWideChange(collaboratorUpdateAction, collaborators);
  };

  return (
    <ConfigProvider renderEmpty={emptyRender}>
      <Select
        value={collaboratorUpdateAction}
        onChange={action => handleActionChange(action)}
        disabled={disabled}
        style={{ margin: '0 0 4px 0' }}
        placeholder={'Select an edit option'}
      >
        {actionOptions.map(({ label, value }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <CollaboratorChooser
        hidden={
          collaboratorUpdateAction && ['APPEND', 'REPLACE'].includes(collaboratorUpdateAction)
            ? false
            : true
        }
        onChange={collaborators => handleCollaboratorChange(collaborators)}
      />
    </ConfigProvider>
  );
};
