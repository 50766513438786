import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  ExperimentOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd5/es/menu/hooks/useItems';

import { Button, Dropdown, Header, Spin, useMessage, WarningAlert } from 'components/ui';
import { useAuthContext } from 'utils/auth';
import { FlexContainer, SpacedContainer } from 'utils/layouts/containers';

import { CustomerSwitcher } from './CustomerSwitcher';
import { GlobalProgressButton } from 'horizon/components/GlobalProgressIndicator/GlobalProgressButton';
import { useGenericProgressContext } from 'horizon/components/GlobalProgressIndicator/GenericProgress/GenericProgressContext';
import { useUploadContext } from './FileUploader';
import { NotificationsButton } from './Notifications';
import { notEmpty } from 'utils/types';
import { useDisableReleaseToggles } from 'utils/release-toggles';
import { CopyUrlButton } from 'horizon/components/OrgRedirect';

const StyledHeader = styled(Header)`
  background: ${({ theme }) => theme.white} !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem !important;
  position: relative;
`;

const horizonUserMenuItems = (
  handleLogout: () => void,
  canManageReleaseToggles: boolean,
  releaseTogglesDisabled: boolean,
  toggleDisabledReleaseToggles: () => void
): ItemType[] => {
  const menuItems: ItemType[] = [
    {
      key: 'user-guide',
      label: (
        <a
          href="https://guide.skyspecs.com/horizon/-MPobGVTLxgvA9kxtck7/"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      ),
      icon: <InfoCircleOutlined />,
    },
    {
      key: 'support',
      label: (
        <a href="https://support.skyspecs.com/" target="_blank" rel="noopener noreferrer">
          Support
        </a>
      ),
      icon: <QuestionCircleOutlined />,
    },
    { key: 'divider-1', type: 'divider' },
    {
      key: 'settings',
      label: <Link to="/settings">Settings</Link>,
      icon: <SettingOutlined />,
    },
    canManageReleaseToggles && {
      key: 'new-features',
      label: (
        <Link onClick={toggleDisabledReleaseToggles} to="#" data-testid="user-menu-release-toggles">
          {releaseTogglesDisabled ? 'Enable' : 'Disable'} New Features
        </Link>
      ),
      icon: <ExperimentOutlined />,
    },
    canManageReleaseToggles && { key: 'divider-2', type: 'divider' },
    {
      key: 'logout',
      label: <span onClick={handleLogout}>Logout</span>,
      icon: <LogoutOutlined />,
    },
  ].filter(notEmpty);

  return menuItems;
};

export function GlobalHeader({ content }: { content: React.ReactNode }) {
  const message = useMessage();
  const { canManageReleaseToggles, releaseTogglesDisabled, toggleDisabledReleaseToggles } =
    useDisableReleaseToggles();
  const [onLine, setOnLine] = useState(navigator.onLine);
  const { actionInProgress } = useGenericProgressContext();
  const { uploading, modalVisible, setConfirmProps } = useUploadContext();
  const { logout, user } = useAuthContext();

  useEffect(() => {
    // notify online/offline
    window.addEventListener('offline', displayConnectionStatus);
    window.addEventListener('online', displayConnectionStatus);
    // for hotkeys
    window.addEventListener('keydown', openUserGuide);

    return () => {
      window.removeEventListener('offline', displayConnectionStatus);
      window.removeEventListener('online', displayConnectionStatus);
      window.removeEventListener('keydown', openUserGuide);
    };
  }, []);

  const handleLogout = () => {
    if (uploading) {
      setConfirmProps({
        onOk: () => logout({ broadcast: true }),
        message: 'You have uploads in progress. Are you sure you want to log out?',
      });
    } else {
      logout({ broadcast: true });
    }
  };

  function displayConnectionStatus() {
    if (navigator.onLine) {
      message.success('You are back online', 5);
    }

    setOnLine(navigator.onLine);
  }

  function openUserGuide(e: KeyboardEvent) {
    // F1 for hot key
    if (e.code === 'F1') {
      // prevent chrome from opening help menu
      e.preventDefault();
      const win = window.open('https://guide.skyspecs.com');
      if (win) {
        win.opener = null;
      }
    }
  }

  return (
    <StyledHeader>
      {content}
      <FlexContainer>
        {!onLine && (
          <SpacedContainer>
            <WarningAlert showIcon={true} message="You are offline. Attempting to reconnect..." />
          </SpacedContainer>
        )}

        {actionInProgress || (uploading && !modalVisible) ? (
          <SpacedContainer>
            <GlobalProgressButton />
          </SpacedContainer>
        ) : null}

        <SpacedContainer>
          <CopyUrlButton />
        </SpacedContainer>

        <SpacedContainer>
          <NotificationsButton />
        </SpacedContainer>

        <CustomerSwitcher />

        <Dropdown
          className="user-menu-dropdown"
          menu={{
            items: horizonUserMenuItems(
              handleLogout,
              canManageReleaseToggles,
              releaseTogglesDisabled,
              toggleDisabledReleaseToggles
            ),
          }}
          trigger={['hover']}
        >
          <Button type="primary" ghost style={{ border: 'none' }} data-testid="user-menu-icon">
            <UserOutlined />
            &nbsp;
            {user?.given_name || <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Dropdown>
      </FlexContainer>
    </StyledHeader>
  );
}
