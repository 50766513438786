import styled from 'styled-components';
import { Checkbox } from 'antd5';
import { InfoCircleOutlined } from '@ant-design/icons';

export const StyledCheckboxContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-top: 0.25rem;
`;

export const StyledCheckBox = styled(Checkbox)`
  align-self: right;
`;

export const StyledInfoCircle = styled(InfoCircleOutlined)`
  padding-left: 0.25rem;
`;
