// Logos, icons, and image constants
import LOGO_FULL from '/logos/horizon/teal/horizon_logo_teal_full.png';
import LOGO_ICON from '/logos/horizon/teal/horizon_icon_teal.png';
import SKYSPECS_LOGO from '/logos/skyspecs/skyspecs-black.png';
import TURBINE_ICON from '/icons/wind-turbine.svg';
import TURBINE_OUTLINED_ICON from '/icons/wind-turbine-outlined.svg';
import FULL_TURBINE_IMAGE from '/icons/turbine_outline.svg';
import MARKER_BLUE from '/icons/marker-blue.png';
import CIRCLE_MINOR_ICON from '/icons/circle-minor.svg';
import CIRCLE_GOOD_ICON from '/icons/circle-good.svg';
import SQUARE_POOR_ICON from '/icons/square-poor.svg';
import TRIANGLE_MAJOR_ICON from '/icons/triangle-major.svg';
import TRIANGLE_CRITICAL_ICON from '/icons/triangle-critical.svg';
import COMPOSITE_FILLED from '/icons/CompositeFilled.svg';
import GRID_VIEW_FILLED from '/icons/GridViewFilled.svg';
import { AnyPicture } from 'horizon/routes/Inspections/types';

export {
  LOGO_FULL,
  LOGO_ICON,
  SKYSPECS_LOGO,
  TURBINE_ICON,
  TURBINE_OUTLINED_ICON,
  FULL_TURBINE_IMAGE,
  MARKER_BLUE,
  CIRCLE_MINOR_ICON,
  CIRCLE_GOOD_ICON,
  SQUARE_POOR_ICON,
  TRIANGLE_MAJOR_ICON,
  TRIANGLE_CRITICAL_ICON,
  COMPOSITE_FILLED,
  GRID_VIEW_FILLED,
};

// App constants
export const TABLE_SCROLL_WIDTH = 1280;
export const TABLE_SCROLL_WIDTH_SMALL = 640;
export const COMPONENT_PICKER_WIDTH_SMALL = 1140;
export const STANDARD_COLUMN_SCROLL_Y = { y: 250 };

// format constants
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

// Log field types constants
export const STRING = 'STRING';
export const NUMBER = 'NUMBER';
export const MULTI = 'MULTI';
export const TIME_RANGE = 'TIME_RANGE';
export const CALCULATED = 'CALCULATED';
export const DATE = 'DATE';

// Work container field type constants
export const SELECT = 'SELECT';
export const MULTI_SELECT = 'MULTI_SELECT';
export const TEXT = 'TEXT';

// Tasks
export const TASK_TYPE_REPAIR = 'REPAIR';
export const REPAIR_TASK = 'REPAIR_TASK';
export const TASK_TYPE_OTHER = 'OTHER';
export const OTHER_TASK = 'OTHER_TASK';
export const OTHER_TASK_TYPE_NAME = 'OtherAssetTask';
export const TASK_TYPE_INSPECT = 'INSPECT';
export const INSPECT_TASK = 'INSPECT_TASK';
export const TASK_TYPE_INTERNAL_BLADE_INSPECTION = 'INTERNAL_BLADE_INSPECTION';
export const INTERNAL_BLADE_INSPECTION_TASK = 'INTERNAL_BLADE_INSPECTION_TASK';
export const TASK_TYPE_INSPECTION = 'INSPECTION';
export const INSPECTION_TASK = 'INSPECTION_TASK';
export const MAINTENANCE_ACTIVITY = 'maintenance-activity';
export const TASK_LEVEL_TYPES = [
  'REPAIR_TASK',
  'INSPECT_TASK',
  'INTERNAL_BLADE_INSPECTION_TASK',
  'OTHER_TASK',
  'INSPECTION_TASK',
];
export const INSPECTION_TASK_DISPLAY_NAME = 'Inspection Task';
export const OTHER_TASK_DISPLAY_NAME = 'Other Task';
export const INTERNAL_BLADE_INSPECTION_DISPLAY_NAME = 'Internal Blade Inspection';

// Work Orders / Campaigns
export const CAMPAIGN = 'CAMPAIGN';
export const WORK_CAMPAIGN = 'WORK_CAMPAIGN';
export const WORK_ORDER = 'WORK_ORDER';

// Assets
export const ASSET_TYPE_SITE = 'Site';
export const ASSET_TYPE_LOCATION = 'Location';
export const ASSET_TYPE_TURBINE = 'Turbine';
export const ASSET_TYPE_BLADE = 'Blade';
export const NACELLE_ASSET_TYPE = 'Nacelle';
export const ASSET_TYPE_GENERIC = 'GenericAsset';

// stats (field definitions)
export const SUM = 'sum';
export const MEAN = 'mean';

// inspection vendor
export const CORNIS = 'cornis';
export const SKYSPECS = 'skyspecs';
export const THIRD_PARTY = 'third party';

export const TASK_STATUSES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const TASK_STATUS_LABELS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

export const LOG_FIELD_TYPE_OPTIONS = {
  [STRING]: 'Text',
  [MULTI]: 'Dropdown',
  [NUMBER]: 'Number',
  [TIME_RANGE]: 'Time Range',
  [CALCULATED]: 'Calculated',
  [DATE]: 'Date',
};

export const WORK_CONTAINER_FIELD_TYPE_OPTIONS = {
  [TEXT]: 'Text',
  [NUMBER]: 'Number',
  [TIME_RANGE]: 'Time Range',
  [DATE]: 'Date',
  [SELECT]: 'Dropdown',
  // [MULTI_SELECT]: 'Multiple Selection',  FIXME: Uncomment this when we can allow multi-select
};

// FIXME: when we can allow multi-select we can remove this and just use WORK_CONTAINER_FIELD_TYPE_OPTIONS for the labels in Field.jsx
export const WORK_CONTAINER_TAG_LABELS = {
  ...WORK_CONTAINER_FIELD_TYPE_OPTIONS,
  [MULTI_SELECT]: 'Multiple Selection',
};

export const WORK_LOG_TYPE_OPTIONS = {
  [WORK_CAMPAIGN]: 'Campaign',
  [WORK_ORDER]: 'Work Order',
  [TASK_TYPE_OTHER]: 'Task',
};

export const WORK_CONTAINER_TYPE_OPTIONS = {
  [CAMPAIGN]: 'Campaign',
  [WORK_ORDER]: 'Work Order',
  [REPAIR_TASK]: 'Repair Task',
  [INSPECT_TASK]: 'Inspect Task',
  [INTERNAL_BLADE_INSPECTION_TASK]: 'Internal Blade Inspection Task',
  [OTHER_TASK]: 'Other Task',
};

export const WORK_CONTAINER = 'WORK_CONTAINER';

export const SEVERITIES = [1, 2, 3, 4, 5];

export const STATS = [SUM, MEAN];

export const INGESTION_CODES = {
  NO_INSPECTION: 'No inspection in progress for turbine',
};

export const TABLE_FILTER_OPERATORS = {
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS',
  CONTAINS: 'CONTAINS',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  IS_NULL: 'IS_NULL',
  IS_NOT_NULL: 'IS_NOT_NULL',
  ARRAY_INCLUDES: 'ARRAY_INCLUDES',
};

// regex for checking for a valid UUID (ex. 82d8f533-03c9-439e-8502-e26093a07c87)
export const uuidRegex =
  '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})';

// regex for checking for a valid ID (ex. 82D8F533 or 82D8F533-5 )
export const idRegex = '[0-9a-z]{8}(-[0-9]+)?';

// Regex to insert commas between groups of 3 digits in numbers
export const numberCommaRegex = /\B(?=(\d{3})+(?!\d))/g;

// TODO: Update values and reference for inspection
// filters once INSPECTIONS_MERGE feature flag
// has been removed
export const INSPECTION_UPLOAD_STATUSES = {
  DRAFT: 'DRAFT',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};
export const BLADE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE_STORAGE: 'INACTIVE_STORAGE',
  INACTIVE_EOL: 'INACTIVE_EOL',
};

export const BLADE_STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE_STORAGE: 'Storage',
  INACTIVE_EOL: 'Recycled',
};

// Inspection Type
export const AUTONOMOUS_DRONE = 'Autonomous Drone';
export const BLADE_EXTERNAL = 'Blade External';
export const BLADE_EXTERNAL_OTHER = 'Blade External Other';
export const BLADE_INTERNAL = 'Blade Internal';
export const BLADE_INTERNAL_LEGACY = 'Blade Internal (Legacy)';
export const GEARBOX_BORESCOPE = 'Gearbox Borescope';
export const GENERIC = 'Generic';
export const GROUND_PHOTO = 'Ground Photo';
export const MANUAL_DRONE = 'Manual Drone';
export const ROPE_INSPECTION = 'Rope Inspection';
export const TOWER_EXTERNAL = 'Tower External';
export const TRANSITION_PIECE = 'Transition Piece';
export const NACELLE_EXTERNAL = 'Nacelle External';

// KE: This is a very general assumption at this point, and will not necessarily
// be accurate long-term. How inspection views are differentiated will
// change as we continue to define how the 'Other' type functions, as well when we
// add new inspection types.
export const isBladeInspection = (inspectionType?: string) =>
  !!inspectionType &&
  (inspectionType === AUTONOMOUS_DRONE ||
    inspectionType === BLADE_EXTERNAL ||
    inspectionType === BLADE_EXTERNAL_OTHER ||
    inspectionType === BLADE_INTERNAL ||
    inspectionType === GROUND_PHOTO ||
    inspectionType === MANUAL_DRONE ||
    inspectionType === ROPE_INSPECTION);

export const isTowerOrTPInspection = (inspectionType?: string) =>
  !!inspectionType && (inspectionType === TOWER_EXTERNAL || inspectionType === TRANSITION_PIECE);

export const isTowerInspection = (inspectionType?: string) =>
  !!inspectionType && inspectionType === TOWER_EXTERNAL;

export const isTPInspection = (inspectionType?: string) =>
  !!inspectionType && inspectionType === TRANSITION_PIECE;

export const isNacelleInspection = (inspectionType?: string) =>
  !!inspectionType && inspectionType === NACELLE_EXTERNAL;

export enum TargetDistanceType {
  BLADE = 'BLADE',
  TOWER_TP_HEIGHT = 'TOWER_TP_HEIGHT',
  TOWER_TP_DISTANCE = 'TOWER_TP_DISTANCE',
  OTHER = 'OTHER',
}

// For most Inspection displays we only need to know if the type is "Nacelle", "Tower or TP", or "Blade". This compresses the type to four options so we can use more simple logic.
export const getTargetDistanceTypeWithoutPictures = (
  inspectionType: string | undefined,
  nacelleSides?: string[],
  towerSides?: string[]
): TargetDistanceType => {
  if (isBladeInspection(inspectionType)) return TargetDistanceType.BLADE;
  if (isTowerOrTPInspection(inspectionType)) {
    if (nacelleSides) return TargetDistanceType.TOWER_TP_DISTANCE;
    if (towerSides) return TargetDistanceType.TOWER_TP_HEIGHT;
  }
  return TargetDistanceType.OTHER;
};

// When we remove rtv1:inspection-image-loading-improvements we can remove this function in favor of getTargetDistanceTypeWithoutPictures
export const getTargetDistanceType = (
  inspectionType: string | undefined,
  pictures: AnyPicture[]
): TargetDistanceType => {
  if (isBladeInspection(inspectionType)) return TargetDistanceType.BLADE;
  if (isTowerOrTPInspection(inspectionType)) {
    const target = pictures[0]?.target;
    // If there is a non-null distanceFromNacelleCenter value in the picture, set the type to distance
    if ('distanceFromNacelleCenter' in target && target?.distanceFromNacelleCenter) {
      return TargetDistanceType.TOWER_TP_DISTANCE;
    } else {
      // Otherwise, fall back to Height
      return TargetDistanceType.TOWER_TP_HEIGHT;
    }
  }
  return TargetDistanceType.OTHER;
};

export type DropdownOptionsType = {
  id: number;
  label: string;
  order: number;
  value: string;
};

export type ReadableOptionsType = {
  [key: string]: {
    label: string;
    order: number;
  };
};

export const READABLE_SIDE_OPTIONS: ReadableOptionsType = {
  leading_edge: {
    label: 'Leading Edge',
    order: 1,
  },
  pressure_side: {
    label: 'Pressure Side',
    order: 2,
  },
  suction_side: {
    label: 'Suction Side',
    order: 3,
  },
  trailing_edge: {
    label: 'Trailing Edge',
    order: 4,
  },
  ungrouped: {
    label: 'ungrouped',
    order: 5,
  },
};

export const READABLE_CHAMBER_OPTIONS: ReadableOptionsType = {
  'trailing-edge': {
    label: 'Trailing Edge',
    order: 4,
  },
  trailing_edge: {
    label: 'Trailing Edge',
    order: 4,
  },
  root: {
    label: 'Root',
    order: 0,
  },
  'leading-edge': {
    label: 'Leading Edge',
    order: 1,
  },
  leading_edge: {
    label: 'Leading Edge',
    order: 1,
  },
  'between-web-1': {
    label: 'Between Web 1',
    order: 2,
  },
  between_web_1: {
    label: 'Between Web 1',
    order: 2,
  },
  'between-web-2': {
    label: 'Between Web 2',
    order: 3,
  },
  between_web_2: {
    label: 'Between Web 2',
    order: 3,
  },
  ungrouped: {
    label: 'ungrouped',
    order: 5,
  },
};

export const READABLE_TOWER_SIDE_OPTIONS: ReadableOptionsType = {
  north: {
    label: 'north',
    order: 1,
  },
  east: {
    label: 'east',
    order: 2,
  },
  south: {
    label: 'south',
    order: 3,
  },
  west: {
    label: 'west',
    order: 4,
  },
  ungrouped: {
    label: 'ungrouped',
    order: 5,
  },
};

export const READABLE_NACELLE_SIDE_OPTIONS: ReadableOptionsType = {
  front: {
    label: 'front',
    order: 1,
  },
  left: {
    label: 'left',
    order: 2,
  },
  back: {
    label: 'back',
    order: 3,
  },
  right: {
    label: 'right',
    order: 4,
  },
  top: {
    label: 'top',
    order: 5,
  },
  bottom: {
    label: 'bottom',
    order: 6,
  },
  ungrouped: {
    label: 'ungrouped',
    order: 7,
  },
};

export const IMAGE_AND_METADATA_UPLOAD_OPTIONS = {
  SKYSPECS_JSON: 'inspection-json',
  SKYSPECS_CSV: 'inspection-csv',
  CORNIS: 'inspection-cornis',
};

export const METADATA_UPLOAD_OPTIONS = {
  SKYSPECS_JSON: 'inspection-metadata-json',
  SKYSPECS_CSV: 'inspection-metadata-csv',
  CORNIS: 'inspection-metadata-cornis',
  UNUSED: 'inspection-metadata-ndjson',
  CORNIS_BULK: 'inspection-bulk-cornis',
  CORNIS_BULK_VALIDATE: 'inspection-bulk-cornis-validate',
  CORNIS_BULK_VALIDATED: 'inspection-bulk-cornis-validated',
};

export const CORNIS_BULK_UPLOAD_OPTIONS = {
  CORNIS_BULK: 'inspection-bulk-cornis',
  CORNIS_BULK_VALIDATE: 'inspection-bulk-cornis-validate',
  CORNIS_BULK_VALIDATED: 'inspection-bulk-cornis-validated',
  CORNIS_BULK_GENERATE: 'inspection-bulk-cornis-generate',
};

export const CSV_BULK_UPLOAD_OPTIONS = {
  CSV_BULK: 'inspection-bulk-csv',
  CSV_BULK_VALIDATE: 'inspection-bulk-csv-validate',
  CSV_BULK_VALIDATED: 'inspection-bulk-csv-validated',
  CSV_BULK_GENERATE: 'inspection-bulk-csv-generate',
};

export const JSON_BULK_UPLOAD_OPTIONS = {
  JSON_BULK: 'inspection-bulk-json',
  JSON_BULK_VALIDATE: 'inspection-bulk-json-validate',
  JSON_BULK_VALIDATED: 'inspection-bulk-json-validated',
  JSON_BULK_GENERATE: 'inspection-bulk-json-generate',
};

export const SKYSPECS_ORG = '28435de4-15bb-11e9-a171-f3fcecd20738';

export const PICTURE_NOT_FOUND_ERR_MESSAGE = 'Picture not found.';
export const UNABLE_TO_LOAD_IMG_ERR_MESASAGE =
  'Unable to load image. Please go back or refresh the page.';

// This is the classname for the actual element that emits scroll events
export const APP_SCROLL_CONTAINER_CLASSNAME = '.scrollable-app-content-wrapper';
