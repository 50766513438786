import { FC, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps } from 'antd5';

dayjs.extend(utc);

type DayJsUTCDatePickerProps = AntdDatePickerProps & {
  defaultHourUTC?: number;
};

/***
 * This component handles displaying local time but saving UTC time using DayJS.
 * Most date handling is still using moment and the other UTCDatePicker handles that.
 */
export const DayJsUTCDatePicker: FC<DayJsUTCDatePickerProps> = ({
  value,
  format = 'YYYY-MM-DD',
  onChange,
  defaultHourUTC = 10,
  ...props
}) => {
  const localValue = value ? value.local() : null;

  const handleChange = useCallback(
    (date: dayjs.Dayjs | null, dateString: string): void => {
      if (date) {
        const baseDate = date.utc();
        // If it's date-only format then we'll add 10:00 to the utc time
        // so it works well for mostly timezone considerations
        const newDate =
          format === 'YYYY-MM-DD' ? baseDate.hour(defaultHourUTC).minute(0).second(0) : baseDate;
        onChange?.(newDate, dateString);
      } else {
        onChange?.(null, '');
      }
    },
    [defaultHourUTC, format, onChange]
  );

  return <AntdDatePicker {...props} onChange={handleChange} value={localValue} />;
};
