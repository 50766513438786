import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { getDefaultStore } from 'jotai';
import { notifyBugsnag } from 'utils/bugsnag';
import { API_HOSTNAME, APP_NAME, ATLAS_API_HOSTNAME, APP_VERSION } from 'utils/env';
import { sha256 } from 'crypto-hash';
import { debounce } from 'lodash';
import ApolloContext from './ApolloContext';
import { useAuthContext } from 'utils/auth';

import Bugsnag from '@bugsnag/js';
import {
  currentCustomerAtom,
  customerIdMismatchAtom,
  releaseTogglesDisabledAtom,
} from 'utils/account/atoms';
import { useHistory } from 'react-router-dom';

const orgMismatchMsg = debounce(() => {
  console.info(
    "One or more GraphQL queries have been ignored because there's a descrepancy in customer IDs. If you are stuck, close this browser tab and open a new Horizon tab."
  );
}, 500);

export function getClient({ apiHostName, cacheOptions = {}, getAuthHeader, historyReplace }) {
  const jotaiStore = getDefaultStore();

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    Bugsnag.addMetadata('GraphQL', { operationName: operation.operationName });

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const message = `[GraphQL error]: ${JSON.stringify(error)}`;
        if (
          // Stargate returns 401s like this. only log the user out when the 401
          // does not come from the `GetNotifications` GQL request
          (message.includes('401: Unauthorized') &&
            operation.operationName !== 'GetNotifications') ||
          // PAPI returns 401s like this
          message.includes(
            'Context creation failed: AuthenticationError: User does not have access to organization'
          )
        ) {
          console.warn('User is unauthorized, logging out');
          historyReplace('/logout');
        }
        console.error(message);
        notifyBugsnag(message);
      });
      Bugsnag.clearMetadata('GraphQL');
    } else if (navigator.onLine && networkError) {
      console.error(`[Network error (${operation.operationName})]: ${networkError}`);
      setTimeout(() => {
        notifyBugsnag(
          new Error(`[GraphQL Network error (${operation.operationName})]: ${networkError}`)
        );
        Bugsnag.clearMetadata('GraphQL');
      }, 10000);
    }
  });

  const retryLink = new RetryLink({
    // @see https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
    attempts: (count, operation) => {
      // it is assumed that "retry" attempts are additional request attempts.
      // e.g. if `retryAttempts` is 0, that means, only run the request once. if
      // `retryAttempts` is 1, only run the request up to 2 times (initial
      // request + 1 retry).
      //
      // the default is 3 attempts (aka, 2 retry attempts)
      const contextualRetryAttempts = operation.getContext().retryAttempts;
      const retryAttempts = contextualRetryAttempts ?? 2;

      if (
        operation.query.kind === 'Document' &&
        operation.query.definitions[0].operation === 'mutation' &&
        typeof contextualRetryAttempts === 'undefined'
      ) {
        // it could be problematic if mutations are retried, so don't do it by
        // default
        return false;
      }

      return count < retryAttempts + 1;
    },
  });

  const authLink = new ApolloLink(async (operation, forward) => {
    const authHeader = await getAuthHeader();
    const { headers } = operation.getContext();

    operation.setContext({
      headers: {
        ...headers,
        ...authHeader,
      },
    });

    // return the headers to the context so httpLink can read them
    return forward(operation);
  });

  // this link will determine if there's a mismatch between the currently-viewed
  // organization and the organization in local storage. these may get out of
  // sync if multiple browser tabs are open and the user changes orgs on one of
  // them, causing a discrepancy across tabs. when there's a mismatch, this link
  // will prevent any queries or mutations from being submitted.
  const orgMismatchLink = new ApolloLink((operation, forward) => {
    const customerIdMismatch = jotaiStore.get(customerIdMismatchAtom);

    if (customerIdMismatch) {
      // returning without forward() will prevent the operation from being executed
      // @see https://www.apollographql.com/docs/react/api/link/introduction/#the-forward-function
      orgMismatchMsg();
      return;
    }

    return forward(operation);
  });

  const getHeader = (name, value) => (value ? { [name]: value } : undefined);

  const customerLink = new ApolloLink((operation, forward) => {
    const customerId = jotaiStore.get(currentCustomerAtom);
    if (!customerId) return forward(operation);

    const { headers } = operation.getContext();
    const disabledReleaseToggles = jotaiStore.get(releaseTogglesDisabledAtom);

    operation.setContext({
      headers: {
        ...headers,
        ...getHeader('X-SkySpecs-Customer', customerId),
        ...getHeader('X-SkySpecs-IgnoreToggles', disabledReleaseToggles?.join(',')),
      },
    });

    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: `${apiHostName}/graphql`,
    preserveHeaderCase: true,
  });

  const cache = new InMemoryCache({
    possibleTypes: {
      Task: [
        'RepairTask',
        'InspectTask',
        'DamageRepairTask',
        'DamageInspectTask',
        'OtherTask',
        'OtherAssetTask',
        'InternalBladeInspectionTask',
        'InspectionTask',
      ],
      DamageSchemaAttributeDefinition: [
        'DamageSchemaAttributeDefinitionString',
        'DamageSchemaAttributeDefinitionNumber',
        'DamageSchemaAttributeDefinitionDate',
      ],
      DamageSchemaConstraintCriteria: [
        'DamageSchemaConstraintCriteriaString',
        'DamageSchemaConstraintCriteriaNumber',
        'DamageSchemaConstraintCriteriaDate',
      ],
      DamageSchemaAttributeHistorical: [
        'DamageSchemaAttributeHistoricalNumber',
        'DamageSchemaAttributeHistoricalString',
        'DamageSchemaAttributeHistoricalDate',
      ],
      AssetFieldValue: [
        'AssetFieldValueText',
        'AssetFieldValueNumber',
        'AssetFieldValueSelect',
        'AssetFieldValueMultiSelect',
        'AssetFieldValueDate',
        'AssetFieldValueFloat',
        'AssetFieldValueBool',
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          alerts: {
            merge: false,
          },
          getHorizonDamages: {
            merge: (_existing, incoming) => incoming,
          },
          unplannedWorkOrders: {
            merge: (_existing, incoming) => incoming,
          },
        },
      },
      Image: {
        keyFields: image => `Image:${image.id ? image.id : image.url}`,
      },
      CD_Image: {
        keyFields: image => `Image:${image.id ? image.id : image.url}`,
      },
      GlobalWorkContainerField: {
        // disables normalization, embeds GlobalWorkContainerField within parent object in cache.
        keyFields: field => false,
      },
      Picture: {
        fields: {
          damages: {
            merge: false,
          },
          target: {
            merge: false,
          },
        },
      },
      AnnotationObservation: {
        fields: {
          damages: {
            merge: false,
          },
        },
      },
      AnnotationGroup: {
        merge: false,
      },
      CD_Location: {
        fields: {
          turbines: {
            merge: false,
          },
        },
      },
      // prevent apollo client from caching role objects by id since id's aren't unique yet
      Role: {
        keyFields: field => false,
      },
      CD_Role: {
        keyFields: field => false,
      },
      AssetFieldDefinition: {
        keyFields: ['id'],
        merge: false,
      },
      AssetFieldValue: {
        keyFields: ['id', 'fieldDefinition', ['id']],
      },
      NotificationTypeMatchCriteria: {
        keyFields: ['criteriaName'],
      },
      NotificationSubscriptionMatchCriteria: {
        keyFields: ['notificationTypeMatchCriteria', ['criteriaName']],
        merge: true,
      },
    },
    ...cacheOptions,
  });

  const defaultOptions = {
    errorPolicy: 'all',
  };

  let links = [authLink, orgMismatchLink, customerLink, errorLink, retryLink, httpLink];

  // Since PAPI is a lambda, persisted queries will never work with it.
  // So add the link only to the atlas client.
  if (apiHostName === ATLAS_API_HOSTNAME) {
    const persistedQueryLink = createPersistedQueryLink({ sha256 });
    // Insert before the last link, since persistedQuery cannot be a terminal link
    links.splice(links.length - 1, 0, persistedQueryLink);
  }

  const client = new ApolloClient({
    link: from(links),
    cache,
    name: APP_NAME, // Lets us know what's calling stuff from inside apollo studio
    version: APP_VERSION,
    // connectToDevTools: apiHostName === DEBUG_HOSTNAME, // Uncomment if you want to inspect a specific client's cache using in-browser devTools.
  });

  client.defaultOptions = {
    query: defaultOptions,
    watchQuery: defaultOptions,
  };

  return client;
}

export const ApolloClientProvider = props => {
  const { getAuthHeader } = useAuthContext();
  const { replace } = useHistory();

  const atlasClient = useMemo(
    () =>
      getClient({
        apiHostName: ATLAS_API_HOSTNAME,
        getAuthHeader,
        historyReplace: replace,
      }),
    [getAuthHeader, replace]
  );

  const papiClient = useMemo(
    () =>
      getClient({
        apiHostName: API_HOSTNAME,
        getAuthHeader,
        historyReplace: replace,
      }),
    [getAuthHeader, replace]
  );

  const resetCache = useMemo(() => {
    return async () => {
      // clearStore will remove all the data from the store, and will not refetch
      // any active queries.
      // @see https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.clearStore
      await papiClient.clearStore();
      await atlasClient.clearStore();
    };
  }, [papiClient, atlasClient]);

  const resetStore = useMemo(() => {
    return async () => {
      // resetStore will remove all the data from the store and then refetch any
      // active queries.
      // @see https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.resetStore
      await papiClient.resetStore();
      await atlasClient.resetStore();
    };
  }, [papiClient, atlasClient]);

  return (
    <ApolloContext.Provider value={{ atlasClient, papiClient, resetCache, resetStore }}>
      <ApolloProvider {...props} client={atlasClient} />
    </ApolloContext.Provider>
  );
};

// Export all methods from a single module
export * from '@apollo/client';
export { graphql } from '@apollo/client/react/hoc';
