import { useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { Form, Input, Modal } from 'antd';
import { Button, MessageType, ModalActionButtons, useMessage } from 'components/ui';

import {
  AtlasGqlFilterOperator,
  AtlasGqlDamageSearchMatch,
  AtlasGqlHorizonDamage,
  useDamageSearchLazyQuery,
  useGetLegacyDamagesForSearchLazyQuery,
} from 'types/atlas-graphql';

import { formatDamageId, getObservationsFromDamage } from 'horizon/components/Damages/utils';
import { uuidRegex as uuidRegexString } from 'utils/constants';
import { idRegex as idRegexString } from 'utils/constants';
import { StyledList } from './DamageSearch.style';
import { AutoFocus } from 'horizon/components/AutoFocus';

type DamageSearchProps = { isOpen: boolean; onClose: () => void };

export const DamageSearch: React.FunctionComponent<DamageSearchProps> = ({ isOpen, onClose }) => {
  const message = useMessage();
  const inputRef = useRef<Input>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const history = useHistory();

  const [form] = Form.useForm();

  const searchRegex = new RegExp(`^${searchQuery}`, 'i');
  const uuidRegex = new RegExp(`^${uuidRegexString}$`, 'i');
  const idRegex = new RegExp(`^${idRegexString}$`, 'i');

  const damageLink = (damageId: string) => (
    <a href={`/damages2/${damageId}`} target="_blank" rel="noreferrer">
      {formatDamageId({ id: damageId })}
    </a>
  );

  // query to search for a valid horizon damage
  const [damageSearch, { loading: damageSearchLoading }] = useDamageSearchLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const results = data.damageSearch?.results ?? [];

      const processedResults = results.reduce(
        (
          acc: {
            damageId: string;
            observationId?: string;
            message: JSX.Element;
          }[],
          result
        ) => {
          const { match, damage } = result ?? {};
          if (match && damage) {
            const observations = getObservationsFromDamage(damage as AtlasGqlHorizonDamage);

            switch (match) {
              // horizon damage found
              case AtlasGqlDamageSearchMatch.HorizonDamageUuid:
              case AtlasGqlDamageSearchMatch.HorizonDamageId:
                acc.push({
                  damageId: damage.id,
                  message: (
                    <>
                      '{searchInput}' is a Damage ID/UUID and corresponds to Damage{' '}
                      {damageLink(damage.id)}
                    </>
                  ),
                });
                break;

              // horizon observation found
              case AtlasGqlDamageSearchMatch.HorizonDamageObservationUuid:
              case AtlasGqlDamageSearchMatch.HorizonDamageObservationId:
                acc.push({
                  damageId: damage.id,
                  observationId: observations.find(o => searchRegex.test(o.id))?.id,
                  message: (
                    <>
                      '{searchInput}' is an Observation ID/UUID and belongs to Damage{' '}
                      {damageLink(damage.id)}
                    </>
                  ),
                });
                break;

              case AtlasGqlDamageSearchMatch.MergedHorizonDamageUuid:
              case AtlasGqlDamageSearchMatch.MergedHorizonDamageId:
                acc.push({
                  damageId: damage.id,
                  message: (
                    <>
                      '{searchInput}' is a Damage ID/UUID and has been linked to Damage{' '}
                      {damageLink(damage.id)}
                    </>
                  ),
                });
                break;

              // legacy damage found
              case AtlasGqlDamageSearchMatch.LegacyDamageUuid:
              case AtlasGqlDamageSearchMatch.LegacyDamageId:
                acc.push({
                  damageId: damage.id,
                  observationId: observations.find(o => o.legacyDamageBranchId === searchQuery)?.id,
                  message: (
                    <>
                      '{searchInput}' is a Legacy Damage ID/UUID and belongs to Damage{' '}
                      {damageLink(damage.id)}
                    </>
                  ),
                });
                break;

              default:
                break;
            }
          }

          return acc;
        },
        []
      );

      if (processedResults.length > 0) {
        // results have been found
        if (processedResults.length === 1) {
          // single damage found -> notify user and redirect
          const { damageId, observationId, message: successMessage } = processedResults[0];
          message.success(successMessage, 4);
          navigateToDamage({ damageId, observationId });
        } else if (processedResults.length > 1) {
          // multiple damages found -> notify user but do not redirect
          message.withClose({
            key: 'damage-search',
            type: MessageType.INFO,
            content: (
              <>
                <span>Multiple results found:</span>
                <StyledList>
                  {processedResults.map((result, index) => (
                    <li key={index}>{result.message}</li>
                  ))}
                </StyledList>
              </>
            ),
          });
        }
      } else {
        // no results have been found
        if (idRegex.test(searchQuery)) {
          // load legacy damage
          loadLegacyDamages(searchInput);
        } else {
          // no valid search results found
          message.error(`'${searchInput}' was not recognized by the system.`, 4);
        }
      }
    },
  });

  // query to get legacy damages
  const [getLegacyDamages, { loading: legacyDamagesLoading }] =
    useGetLegacyDamagesForSearchLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const { id: legacyDamageBranchId } = data.damages?.items?.[0] ?? {};

        if (legacyDamageBranchId) {
          // legacy damage found -> search horizon damages with this id
          loadDamageSearch(legacyDamageBranchId);
        } else {
          // no legacy damage found -> error
          message.error(`'${searchInput}' was not recognized by the system.`, 4);
        }
      },
    });

  // load horizon damages for the search string
  const loadDamageSearch = (search: string) => {
    setSearchQuery(search);
    damageSearch({ variables: { input: { search } } });
  };

  // load legacy damages to get the legacyDamageBranchId
  const loadLegacyDamages = (search: string) => {
    setSearchQuery(search);
    getLegacyDamages({
      variables: {
        offset: 0,
        limit: 10,
        filterBy: [
          {
            key: 'number',
            values: [search],
            operator: AtlasGqlFilterOperator.Equals,
          },
        ],
        sortBy: [],
      },
    });
  };

  // redirect to damage page
  const navigateToDamage = ({
    damageId,
    observationId,
  }: {
    damageId: string;
    observationId?: string;
  }) => {
    setTimeout(() => {
      history.push(`/damages2/${damageId}${observationId ? `?observation=${observationId}` : ''}`);
    }, 2000);
  };

  // handle submit logic
  const handleSubmit = ({ search }: { search: string }) => {
    setSearchInput(search);
    loadDamageSearch(search);
  };

  // handler to close and reset form
  const onFormClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal title="ID Search" visible={isOpen} onCancel={onFormClose} destroyOnClose footer={null}>
      <AutoFocus elementToFocus={inputRef}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="search"
            label="Damage, Observation, or Legacy Damage ID/UUID"
            rules={[
              {
                required: true,
                message: 'Input is required.',
                validateTrigger: 'onFinish',
              },
              {
                pattern: new RegExp(idRegex.source + '|' + uuidRegex.source, 'i'),
                message: (
                  <>
                    <span>Please enter a valid input:</span>
                    <StyledList>
                      <li>ID (ex. A63A2315 or 5XJ9GNMS-7)</li>
                      <li>UUID (ex. a63a2315-73c5-45cd-b108-5310a362839b)</li>
                    </StyledList>
                  </>
                ),
                validateTrigger: 'onFinish',
              },
            ]}
          >
            <Input ref={inputRef} placeholder="Enter an ID or UUID" />
          </Form.Item>
          <ModalActionButtons>
            <Button _version={4} onClick={onFormClose}>
              Cancel
            </Button>
            <Button
              _version={4}
              type="primary"
              htmlType="submit"
              loading={legacyDamagesLoading || damageSearchLoading}
            >
              Search
            </Button>
          </ModalActionButtons>
        </Form>
      </AutoFocus>
    </Modal>
  );
};
