import { BreadcrumbProps } from 'antd5/lib/breadcrumb';
import { Breadcrumb, StyledLink, StyledSkeleton, StyledTitle } from './Breadcrumb.style';

export interface BreadcrumbItem {
  href?: string;
  title: string;
}

interface BreadcrumbsProps extends BreadcrumbProps {
  items: BreadcrumbItem[];
  loading?: boolean;
}

export function Breadcrumbs({ items, loading, ...props }: BreadcrumbsProps) {
  return (
    <Breadcrumb
      items={items.map(({ title, href }, idx, arr) => {
        // Even if loading, show title if its non-dynamic.
        const isConstant = title && !title.toString().match('undefined');
        const innerContent =
          href && !loading ? (
            <StyledLink to={href}>{title}</StyledLink>
          ) : (
            <StyledTitle>{title}</StyledTitle>
          );

        const content =
          loading && !isConstant ? (
            <StyledSkeleton active={true} loading={true} paragraph={false} title={{ width: 50 }} />
          ) : (
            innerContent
          );

        return { key: `${title}__${idx}`, title: content };
      })}
      {...props}
    />
  );
}
