import { useContext, useMemo, useRef } from 'react';
import { Select } from 'antd5';
import { BaseSelectRef } from 'rc-select';
import { Button } from 'components/ui';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  SelectContainer,
} from 'components/data/helpers/filters.style';
import { useGetConfiguredAssetAttributeValuesQuery } from 'types/atlas-graphql';
import { filterOption } from 'components/choosers/helpers';
import { AutoFocus } from 'horizon/components/AutoFocus';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';
import { DataTableContext } from 'components/DataTable';

/**
 * Table column filter component for filtering asset attribute values (applicable only on asset tables)
 *
 * This component takes an asset attribute field name, and optionally an asset type
 * name as props, and provides a list of existing values for that attribute
 * (and type, if appropriate) in the current organization.
 * If site and/or turbine filters are applied in the table, the values are limited to those at
 * the site(s) or on the selected turbine(s).
 *
 * Additionally, there is an optional `independentFieldFilterKey` prop, which, when provided, will result in
 * only options that are valid combinations with any filtered values under that key or are legacy non-saav values
 * (i.e. if filtering turbines and "Vestas" is selected as a make filter, only valid Vestas models and non-saav
 * models will be available for selection here).
 */
type AssetAttributeSelectFilterProps = CustomFilterDropdownProps & {
  fieldName: string;
  assetTypeName?: string;
  independentFieldFilterKey?: string;
};

export const AssetAttributeSelectFilter: React.FunctionComponent<
  AssetAttributeSelectFilterProps
> = ({
  fieldName,
  assetTypeName,
  independentFieldFilterKey,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const inputRef = useRef<BaseSelectRef>(null);
  const selectedAssetIds = selectedKeys ?? [];

  const { filteredInfo } = useContext(DataTableContext);
  const {
    selectedSiteIds,
    selectedTurbineIds,
    selectedInependentFieldValues,
  }: {
    selectedSiteIds: string[];
    selectedTurbineIds: string[];
    selectedInependentFieldValues: string[];
  } = useMemo(
    () => ({
      selectedSiteIds: filteredInfo?.site ?? [],
      selectedTurbineIds: filteredInfo?.asset_turbine ?? [],
      selectedInependentFieldValues: !!independentFieldFilterKey
        ? filteredInfo?.[independentFieldFilterKey] ?? []
        : [],
    }),
    [JSON.stringify(filteredInfo), independentFieldFilterKey]
  );

  const { data, loading } = useGetConfiguredAssetAttributeValuesQuery({
    variables: {
      input: {
        fieldName,
        ...(selectedSiteIds?.length ? { siteIds: selectedSiteIds } : {}),
        ...(selectedTurbineIds?.length ? { ancestorAssetIds: selectedTurbineIds } : {}),
        ...(assetTypeName ? { assetTypeNames: [assetTypeName] } : {}),
      },
      fieldNameForDependentCombinationOptions: fieldName,
    },
  });
  const options = useMemo(() => {
    const _options = [...(data?.configuredAssetFieldValues?.existingValues ?? [])].sort();

    /**
     * If the filter is for a dependent field (e.g. model), and its corresponding independent field (e.g. make)
     * has been filtered, filter the options down to only those that either
     *   a) are a valid combination for one of the selected independent values, or
     *   b) are not a standardized value for combination (i.e. we still want to be able to filter on legacy model
     * values that are not SAAV)
     *
     * If the independent field has not been filtered, all existing options will be available in the dependent field's column
     */
    if (
      data?.assetFieldDefinition.dependentCombinationOptions?.length &&
      selectedInependentFieldValues.length
    ) {
      const { dependentOptionsForSelectedIndependentValues, nonStandardOptions } =
        data.assetFieldDefinition.dependentCombinationOptions.reduce(
          (acc, value) => {
            if (value.independentFieldValue) {
              if (selectedInependentFieldValues.includes(value.independentFieldValue)) {
                const dependentFieldOptions = value.dependentFieldOptions ?? [];
                return {
                  dependentOptionsForSelectedIndependentValues: [
                    ...acc.dependentOptionsForSelectedIndependentValues,
                    ...dependentFieldOptions,
                  ],
                  nonStandardOptions: acc.nonStandardOptions.filter(
                    value => !dependentFieldOptions.includes(value)
                  ),
                };
              } else {
                const dependentFieldOptions = value.dependentFieldOptions ?? [];
                return {
                  dependentOptionsForSelectedIndependentValues:
                    acc.dependentOptionsForSelectedIndependentValues,
                  nonStandardOptions: acc.nonStandardOptions.filter(
                    value => !dependentFieldOptions.includes(value)
                  ),
                };
              }
            }

            return acc;
          },
          {
            dependentOptionsForSelectedIndependentValues: [],
            nonStandardOptions: _options,
          }
        );

      // return all existing values that are valid combinations with filtered independent values or are non-saav
      return _options.filter(value =>
        [...dependentOptionsForSelectedIndependentValues, ...nonStandardOptions].includes(value)
      );
    }

    return _options;
  }, [data?.configuredAssetFieldValues?.existingValues, selectedInependentFieldValues]);

  const handleChange = (value: string[]) => {
    setSelectedKeys(value);
  };

  const handleConfirm = () => {
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    clearFilters && clearFilters();
  };

  return (
    <AutoFocus elementToFocus={inputRef}>
      <CustomFilterPopover className="ant-table-filter-dropdown">
        <CustomFilterContainer>
          <SelectContainer style={{ paddingRight: '44px', width: '240px' }}>
            <Select
              id="asset-attribute-filter-dropdown"
              ref={inputRef}
              placeholder={'Select value...'}
              loading={loading}
              allowClear={true}
              showSearch={true}
              filterOption={filterOption}
              virtual={false}
              value={selectedAssetIds}
              onChange={handleChange}
              mode={'multiple'}
              popupMatchSelectWidth={false}
            >
              {options.map(value => (
                <Select.Option key={value} className="asset-attribute-filter-option" value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </SelectContainer>
        </CustomFilterContainer>
        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button type="link" size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleConfirm}>
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    </AutoFocus>
  );
};
