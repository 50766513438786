import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import snakeCase from 'lodash/snakeCase';
import { clone, get, isEmpty } from 'lodash';

import { ASSET_UPDATE } from 'utils/access-control/rules';
import { None } from 'components/data/helpers';
import { BreadcrumbItem } from 'components/ui';
import { TColumnAttributes } from 'horizon/types/ColumnAttributes';
import { selectObjectInput } from 'utils/editable';
import { TAnyAssetType } from 'components/data/assets/types';
import { getAssetPosition } from 'utils/asset-helpers';
import {
  AtlasGqlAsset,
  AtlasGqlAssetType,
  AtlasGqlAssetFieldDefinition,
  AtlasGqlAssetFieldValue,
  AtlasGqlAssetFieldValueText,
} from 'types/atlas-graphql';
import { ASSET_TYPE_SITE, ASSET_TYPE_TURBINE } from 'utils/constants';
import { StatusTag } from 'components/StatusTag';

export const createBreadcrumbTitle = (name: string, data: TAnyAssetType): string => {
  switch (name) {
    case 'Site':
      const siteUniqueIdentifier = getUniqueIdentifier(data);
      return siteUniqueIdentifier;
    case 'Turbine':
      const turbineUniqueIdentifier = getUniqueIdentifier(data);
      return turbineUniqueIdentifier;
    default:
      const assetPosition = getAssetPosition(data);
      return assetPosition ? `${name} ${assetPosition}` : name;
  }
};

export const createBreadcrumbItem = (data: any): BreadcrumbItem => {
  const { id, assetType } = data;
  const SUPPORTED_ASSET_ROUTES = ['Site', 'Turbine', 'Blade'];
  const typeName = assetType?.name ?? '';
  const urlSlug = SUPPORTED_ASSET_ROUTES.includes(typeName) ? `${snakeCase(typeName)}s` : 'assets';

  return {
    title: createBreadcrumbTitle(typeName, data as TAnyAssetType),
    href: `/${urlSlug}/${id}`,
  };
};

export const createSummaryData = (data: any[]): TColumnAttributes[] => {
  return data.map((item: any) => {
    return {
      key: item.fieldDefinition.id,
      title: item.fieldDefinition.name,
      dataIndex: item.fieldDefinition.id,
      // @ts-ignore 'React' refers to a UMD global, but the current file is a module. Consider adding an import instead.ts(2686)
      render: () => {
        if (item.fieldDefinition.name === 'Status')
          return <StatusTag value={item.selectValue ?? ''} />;

        if (item.textValue) return item.textValue;

        if (item.selectValue) return item.selectValue;

        return <None />;
      },
    };
  });
};

// Returns the value of the uniqueIdLabel field, with the "[AssetTypeName]: " prefix stripped out.
export const getStrippedUniqueIdLabel = (asset: AtlasGqlAsset): string => {
  // If we don't have the uniqueIdLabel field, we can't strip it.
  if (!asset.uniqueIdLabel) return '';

  // If it includes "no serial number" or "no identifier", return it as is. Blades are weird like that.
  const lowerName = asset.uniqueIdLabel.toLowerCase();
  if (lowerName.includes('no serial number') || lowerName.includes('no identifier'))
    return asset.uniqueIdLabel;

  // Otherwise, strip out the AssetTypeName prefix.
  const re = /^(\S)+: /;
  const finalName = asset.uniqueIdLabel.replace(re, '');
  return finalName;
};

export const getUniqueIdentifier = (data: any | undefined): string => {
  if (!data) return '';

  if (data?.uniqueIdLabel) {
    return data.uniqueIdLabel;
  }

  const uniqueIdField: AtlasGqlAssetFieldValueText | undefined = data.fieldValues?.find(
    (item: AtlasGqlAssetFieldValue) => item.fieldDefinition?.isUniqueIdentifierField === true
  );

  if (uniqueIdField && uniqueIdField.textValue) {
    return uniqueIdField.textValue.toString();
  }

  const positionValue = getAssetPosition(data);

  return [
    data.assetType?.name,
    positionValue,
    `(No ${uniqueIdField?.fieldDefinition.name ?? 'identifier'})`,
  ].join(' ');
};

export const filterByAssetTypeIds = (values: string[]) => {
  return [{ key: 'assetTypeIds', values: values }];
};

export const generateParentAssetColumnData = (
  data: any,
  parentAssetOptionsData: any,
  canEditParent: boolean
) => {
  const parentUniqueIdentifier = getUniqueIdentifier(data?.assetWithMetadata?.parent);

  const parentAssetOptions = parentAssetOptionsData.map((item: AtlasGqlAsset) => {
    return { id: item.id, name: getUniqueIdentifier(item) };
  });

  const parentColumn = {
    key: data.assetWithMetadata?.parent?.id,
    defaultValue: parentUniqueIdentifier,
    options: parentAssetOptions,
  };

  const parentAsset: TColumnAttributes = {
    key: data.assetWithMetadata?.parent?.id,
    title: data.assetWithMetadata?.parent?.assetType?.name || 'Parent Asset: ',
    dataIndex: data.assetWithMetadata?.parent?.id,
    get: (record: any) => get(record, 'parent'),
    render: () => (data.assetWithMetadata?.parent?.id ? parentUniqueIdentifier : <None />),
    edit: canEditParent ? selectObjectInput(parentColumn) : undefined,
    editRule: canEditParent ? ASSET_UPDATE : undefined,
  };

  return parentAsset;
};

type TrimmedAtlasGqlAssetType = Pick<AtlasGqlAssetType, 'name'>;
type TrimmedAtlasGqlAssetFieldDefinition = Pick<
  AtlasGqlAssetFieldDefinition,
  'isUniqueIdentifierField' | 'name'
>;
type TrimmedAtlasGqlAssetFieldValue = Pick<AtlasGqlAssetFieldValue, 'id'> & {
  fieldDefinition?: TrimmedAtlasGqlAssetFieldDefinition | null;
  textValue?: string | null;
  selectValue?: string | null;
};
type TrimmedAtlasGqlAsset = Pick<AtlasGqlAsset, 'id'> & {
  ancestors?: TrimmedAtlasGqlAsset[] | null;
  assetType?: TrimmedAtlasGqlAssetType | null;
  fieldValues?: (TrimmedAtlasGqlAssetFieldValue | null | undefined)[] | null;
};

export const getAssetSite = (asset: TrimmedAtlasGqlAsset) => {
  const site =
    asset?.assetType?.name === 'Site'
      ? asset
      : asset?.ancestors?.find(a => a?.assetType?.name === 'Site');

  return {
    identifier: site ? getUniqueIdentifier(site) : '',
    site,
  };
};

export const getAssetByType = (asset: AtlasGqlAsset | undefined | null, assetType: string) => {
  const assetByType =
    asset?.assetType?.name === assetType
      ? asset
      : asset?.ancestors?.find(a => a?.assetType?.name === assetType);

  return assetByType;
};

export const renderAssetSite = (asset: TrimmedAtlasGqlAsset) => {
  const { identifier, site } = getAssetSite(asset);

  return site && !isEmpty(identifier) ? (
    <Link data-testid="asset-site-link" to={`/assets/${site.id}`}>
      {identifier}
    </Link>
  ) : (
    <None />
  );
};

const getOrderedAncestors = (asset: TrimmedAtlasGqlAsset): TrimmedAtlasGqlAsset[] =>
  clone(asset.ancestors ?? []).reverse();

export const getAssetColumnCopy = (asset: TrimmedAtlasGqlAsset): string => {
  return getOrderedAncestors(asset)
    .filter(a => a.assetType?.name !== 'Site')
    .map(a => getUniqueIdentifier(a))
    .concat(getUniqueIdentifier(asset))
    .join(' ');
};

type RenderAssetOptions = {
  includeSite?: boolean;
};

export const renderAsset = (
  asset: TrimmedAtlasGqlAsset,
  { includeSite }: RenderAssetOptions = { includeSite: false }
): React.ReactNode => {
  if (asset.assetType?.name === 'Site') {
    return <None />;
  }

  return (
    <>
      {includeSite && (
        <>
          {renderAssetSite(asset)}
          <Divider type="vertical" />
        </>
      )}
      {getOrderedAncestors(asset)
        .filter(a => a.assetType?.name === 'Turbine')
        .map(a => (
          <span key={a.id}>
            {/* we want to override the default Asset Details tab when navigating from here */}
            <Link to={`/assets/${a.id}/inspections`}>{getUniqueIdentifier(a)}</Link>
            <Divider type="vertical" />
          </span>
        ))}
      {/* we want to override the default Asset Details tab when navigating from here */}
      <Link to={`/assets/${asset.id}/inspections`}>{getUniqueIdentifier(asset)}</Link>
    </>
  );
};

export const getTurbineIdByAsset = (asset: any) => {
  if (!asset) {
    return null;
  }

  const assetType = asset?.__typename;

  if (!assetType) {
    return null;
  }

  if (assetType !== ASSET_TYPE_SITE && assetType !== ASSET_TYPE_TURBINE) {
    const ancestorTurbine = asset.ancestors?.find((asset: any) => {
      return asset?.__typename === ASSET_TYPE_TURBINE;
    });
    return ancestorTurbine?.id;
  }

  if (assetType === ASSET_TYPE_TURBINE) {
    return asset.id;
  }

  return null;
};
