import { Alert as AlertV5, AlertProps } from 'antd5';

interface Props extends AlertProps {}

export function Alert(props: Props) {
  return <AlertV5 {...props} />;
}

export function SuccessAlert(props: Props) {
  return <Alert type="success" {...props} />;
}

export function InfoAlert(props: Props) {
  return <Alert type="info" {...props} />;
}

export function WarningAlert(props: Props) {
  return <Alert type="warning" {...props} />;
}

export function ErrorAlert(props: Props) {
  return <Alert type="error" {...props} />;
}
