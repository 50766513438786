import { Form } from 'antd5';
import { Button, ModalActionButtons, Modal } from 'components/ui';
import { useState } from 'react';
import { DeliveryOrganizationChooser } from 'components/choosers/DeliveryOrganizationChooser';

interface ITaskForOrganizationEditorProps {
  defaultOrgId: string | undefined;
  assetId: string;
  onSave: (id: string) => void;
}
export const TaskForOrganizationEditor: React.FunctionComponent<
  ITaskForOrganizationEditorProps
> = ({ defaultOrgId, onSave, assetId }) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  const formKey = 'forOrganization';

  const handleSave = () => {
    setVisible(false);
    const orgId = form.getFieldValue(formKey);
    onSave(orgId);
  };
  function handleClose() {
    setVisible(false);
  }

  return (
    <Modal title={'Edit For Organization'} open={visible} footer={null} onCancel={handleClose}>
      <Form layout="vertical" form={form} onFinish={handleSave}>
        <Form.Item name={formKey} label="For Organization">
          <DeliveryOrganizationChooser
            formKey={formKey}
            form={form}
            assetIds={[assetId]}
            defaultOrgId={defaultOrgId}
          />
        </Form.Item>
        <ModalActionButtons>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Edit
          </Button>
        </ModalActionButtons>
      </Form>
    </Modal>
  );
};
