import { TBladeFields, TTurbineFields } from './types';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import {
  AtlasGqlAsset,
  AtlasGqlGetAssetFieldsForTypeQuery,
  AtlasGqlSite,
} from 'types/atlas-graphql';
import { sortBy } from '../helpers';
import { generateColumnFieldOptions, getOptionsForField } from './utils';
import { generateDateColumn, generateNumberColumn, generateSelectColumn } from './columnHelpers';
import {
  SiteFilter,
  SimpleAssetFilter,
  AssetAttributeSelectFilter,
} from 'components/DataTable/customFilters';
import { omit } from 'lodash';

// All asset type columns
export const allAssetsTypeName = 'All';
export const allAssetsMenuKey = 'all-assets';

// Asset Column - The unique identifier for the asset
export const assetColumn: TTableColumnDef = {
  key: 'assetName',
  title: 'Asset',
  dataIndex: 'assetName',
  filterProperty: '',
  sorter: false,
};

// Asset Type
export const assetTypeColumn: TTableColumnDef = {
  key: 'assetTypeName',
  title: 'Asset Type',
  dataIndex: 'assetTypeName',
  filterProperty: '',
  sorter: false,
};

// NOTE: The intent is for this to be the generic asset status column for all assets
// TODO: It is currently only supported in the Blade table
// Component Status
export function assetStatusColumn(options: string[]): TTableColumnDef {
  return generateSelectColumn(
    'component_status',
    'Status',
    true,
    options.map(generateColumnFieldOptions)
  );
}

// Location
export const assetLocationColumn: TTableColumnDef = {
  key: 'assetLocation',
  title: 'Location',
  dataIndex: 'assetLocation',
  filterProperty: '',
  sorter: false,
};

// Parent Unique Identifier
export const assetParentColumn: TTableColumnDef = {
  key: 'assetParentName',
  title: 'Parent Asset',
  dataIndex: 'assetParentName',
  filterProperty: '',
  sorter: false,
};

// Parent Asset Type
export const assetParentTypeColumn: TTableColumnDef = {
  key: 'assetParentTypeName',
  title: 'Parent Asset Type',
  dataIndex: 'assetParentTypeName',
  filterProperty: '',
  sorter: false,
};

// Filterable site column
export const filterableSiteColumn: TTableColumnDef<AtlasGqlSite> = {
  className: 'wrap-column',
  key: 'site',
  title: 'Site',
  dataIndex: 'site',
  filterKeyOverride: 'descendantOfId',
  filterDropdown: SiteFilter,
  sorter: true,
};

// Site-specific Column definitions - Hard-coded at least for now.
// Site name - Is the unique identifier for a Site.
// When rtv1:fleet_table_saved_views is cleaned up, this can be removed
export const siteNameColumn: TTableColumnDef = {
  className: 'wrap-column',
  key: 'name',
  title: 'Site',
  dataIndex: 'name',
  filterProperty: 'name',
  defaultFilterOperator: 'CONTAINS',
  sorter: sortBy('name'),
};

export const siteAddressColumn: TTableColumnDef = {
  className: 'wrap-column',
  key: 'address',
  title: 'Address',
  dataIndex: 'address',
  filterProperty: 'address',
  sorter: sortBy('address'),
};

export const siteCityColumn: TTableColumnDef = {
  className: 'wrap-column',
  key: 'city',
  title: 'City',
  dataIndex: 'city',
  filterProperty: 'city',
  sorter: sortBy('city'),
};

export const siteCountryColumn: TTableColumnDef = {
  className: 'wrap-column',
  key: 'country',
  title: 'Country',
  dataIndex: 'country',
  filterProperty: 'country',
  sorter: sortBy('country'),
};

export const siteStateColumn: TTableColumnDef = {
  className: 'wrap-column',
  key: 'state',
  title: 'State',
  dataIndex: 'state',
  filterProperty: 'state',
  sorter: sortBy('state'),
};

export const siteZipColumn: TTableColumnDef = {
  key: 'zip',
  title: 'Zip',
  dataIndex: 'zip',
  filterProperty: 'zip',
  sorter: sortBy('zip'),
};

export const siteTimeZoneColumn: TTableColumnDef = {
  key: 'timezone',
  title: 'Time Zone',
  dataIndex: 'timezone',
  filterProperty: 'timezone',
  sorter: sortBy('timezone'),
};

// turbine count is resolved separately, so filtering/sorting are not available
export const siteTurbinesCountColumn: TTableColumnDef = {
  key: 'turbineCount',
  title: 'Number of Turbines',
  dataIndex: 'turbineCount',
};

export const siteCapacityColumn: TTableColumnDef = {
  key: 'capacity',
  title: 'Capacity',
  dataIndex: 'capacity',
  filterProperty: 'capacity',
  sorter: sortBy('capacity'),
};

// Turbine-Specific Column Definitions
export const turbineColumn: TTableColumnDef<AtlasGqlAsset> = {
  key: 'assetIds',
  title: 'Turbine',
  dataIndex: 'Name',
  filterDropdown: props => <SimpleAssetFilter assetTypeName={'Turbine'} {...props} />,
  sorter: true,
  sortKey: 'name',
};

export const turbineNameColumn: TTableColumnDef = {
  key: 'name',
  title: 'Turbine',
  dataIndex: 'Name',
  filterProperty: 'name',
  sorter: sortBy('name'),
};

export const turbineSiteColumn: TTableColumnDef = {
  key: 'ancestorSiteName',
  title: 'Site',
  dataIndex: 'turbine_site',
  filterProperty: '',
  sorter: true,
};

export function turbineStatusColumn(options: string[]): TTableColumnDef {
  return generateSelectColumn('status', 'Status', true, options.map(generateColumnFieldOptions));
}

export const turbineMakeColumn: TTableColumnDef = {
  key: 'make',
  title: 'Make',
  dataIndex: 'Make',
  filterDropdown: props => <AssetAttributeSelectFilter fieldName="turbine_make" {...props} />,
  sorter: sortBy('make'),
};

export const turbineModelColumn: TTableColumnDef = {
  key: 'model',
  title: 'Model',
  dataIndex: 'Model',
  filterDropdown: props => (
    <AssetAttributeSelectFilter
      fieldName="turbine_model"
      independentFieldFilterKey="make"
      {...props}
    />
  ),
  sorter: sortBy('model'),
};

export const turbineSerialNumberColumn: TTableColumnDef = {
  key: 'serial_number',
  title: 'Serial Number',
  dataIndex: 'Serial Number',
  filterProperty: 'serial_number',
  sorter: sortBy('serial_number'),
};

export const turbineLatitudeColumn: TTableColumnDef = generateNumberColumn(
  'latitude',
  'Latitude',
  true,
  [
    {
      label: -90,
    },
    { label: 90 },
  ]
);

export const turbineLongitudeColumn: TTableColumnDef = generateNumberColumn(
  'longitude',
  'Longitude',
  true,
  [
    {
      label: -180,
    },
    { label: 180 },
  ]
);

export const turbineHubHeightColumn: TTableColumnDef = generateNumberColumn(
  'hub_height',
  'Hub Height',
  true,
  [
    {
      label: 0,
    },
    { label: 1000 },
  ]
);

export const turbineYearOnlineColumn: TTableColumnDef = generateNumberColumn(
  'year_online',
  'Year Online',
  true,
  [
    {
      label: 1950,
    },
    {
      // Always set max as the current year
      label: new Date().getFullYear(),
    },
  ],
  1
);

export const turbineCODDateColumn: TTableColumnDef = generateDateColumn(
  'cod_date',
  'COD Date',
  'COD Date',
  true
);

export const turbineEOWDateColumn: TTableColumnDef = generateDateColumn(
  'eow_date',
  'EOW Date',
  'EOW Date',
  true
);

export const turbineCapacityColumn: TTableColumnDef = generateNumberColumn(
  'capacity',
  'Capacity',
  true,
  [
    {
      label: 0,
    },
    { label: 1000 },
  ]
);

// Blade-specific columns
export const bladeNameColumn: TTableColumnDef = {
  key: 'serial_number',
  title: 'Serial No.',
  dataIndex: 'Name', /// Does not map to FieldDefintion name. Will be populated manually.
  filterProperty: 'serial_number',
  sorter: sortBy('serial_number'),
};

export const bladeTurbineColumn: TTableColumnDef = {
  key: 'ancestorTurbineName',
  title: 'Turbine',
  dataIndex: 'blade_turbine',
  filterProperty: '',
  sorter: true,
};

export const bladeSiteColumn: TTableColumnDef = {
  key: 'ancestorSiteName',
  title: 'Site',
  dataIndex: 'blade_site',
  filterProperty: '',
  sorter: true,
};

export function bladePositionColumn(options: string[]): TTableColumnDef {
  return generateSelectColumn(
    'blade_position',
    'Position',
    true,
    options.map(generateColumnFieldOptions)
  );
}

export const bladeMakeColumn: TTableColumnDef = {
  key: 'make',
  title: 'Make',
  dataIndex: 'Make',
  filterDropdown: props => <AssetAttributeSelectFilter fieldName="blade_make" {...props} />,
  sorter: sortBy('make'),
};

export const bladeModelColumn: TTableColumnDef = {
  key: 'model',
  title: 'Model',
  dataIndex: 'Model',
  filterDropdown: props => (
    <AssetAttributeSelectFilter
      fieldName="blade_model"
      independentFieldFilterKey="make"
      {...props}
    />
  ),
  sorter: sortBy('model'),
};

export function bladeSerialNumberVerifiedColumn(options: string[]): TTableColumnDef {
  return generateSelectColumn(
    'serial_number_verified',
    'Serial Number Verified',
    true,
    options.map(generateColumnFieldOptions)
  );
}

export const bladeLengthColumn: TTableColumnDef = generateNumberColumn('length', 'Length', true, [
  // FIXME JD: Update this to query asset service for the range?
  {
    label: 0,
  },
  { label: 150 },
]);

export const bladeInstallDateColumn: TTableColumnDef = generateDateColumn(
  'install_date',
  'Install Date',
  'Install Date',
  true
);

export const bladeRemovedDateColumn: TTableColumnDef = generateDateColumn(
  'removed_date',
  'Removed Date',
  'Removed Date',
  true
);

export const bladeAuxiliaryComponentsColumn: TTableColumnDef = {
  key: 'auxiliary_component',
  title: 'Auxiliary Component',
  dataIndex: 'Aux Component', // Does not map to FieldDefintion name. Will be populated manually.
  filterProperty: '',
  sorter: false,
};

// Generic Asset Type Columns
export const componentTurbineColumn: TTableColumnDef<AtlasGqlAsset> = {
  key: 'asset_turbine',
  title: 'Turbine',
  dataIndex: 'asset_turbine',
  filterKeyOverride: 'descendantOfId',
  filterDropdown: props => <SimpleAssetFilter assetTypeName={'Turbine'} {...props} />,
  sorter: true,
  sortKey: 'ancestorTurbineName',
};

export const assetTurbineColumn: TTableColumnDef = {
  key: 'ancestorTurbineName',
  title: 'Turbine',
  dataIndex: 'asset_turbine',
  filterProperty: '',
  sorter: true,
};

export const assetSiteColumn: TTableColumnDef = {
  key: 'ancestorSiteName',
  title: 'Site',
  dataIndex: 'asset_site',
  filterProperty: '',
  sorter: true,
};

function getSiteColumns(hasSavedViewsReleaseToggle: boolean) {
  return [
    ...(hasSavedViewsReleaseToggle
      ? [
          {
            ...filterableSiteColumn,
            filterKeyOverride: 'asset_id',
            dataIndex: 'name',
            sortKey: 'name',
          },
        ]
      : [siteNameColumn]),
    siteAddressColumn,
    siteCityColumn,
    siteStateColumn,
    siteZipColumn,
    siteCountryColumn,
    siteTimeZoneColumn,
    siteTurbinesCountColumn,
    siteCapacityColumn,
  ];
}

export function getTurbineColumns(fields: TTurbineFields, hasSavedViewsReleaseToggle: boolean) {
  return [
    ...(hasSavedViewsReleaseToggle
      ? [
          turbineColumn,
          {
            ...filterableSiteColumn,
            dataIndex: 'turbine_site',
            sortKey: 'ancestorSiteName',
          },
        ]
      : [turbineNameColumn, turbineSiteColumn]),
    turbineStatusColumn(fields.statusOptions),
    omit(turbineMakeColumn, hasSavedViewsReleaseToggle ? '' : 'filterDropdown'),
    omit(turbineModelColumn, hasSavedViewsReleaseToggle ? '' : 'filterDropdown'),
    turbineSerialNumberColumn,
    turbineLatitudeColumn,
    turbineLongitudeColumn,
    turbineHubHeightColumn,
    turbineYearOnlineColumn,
    turbineCODDateColumn,
    turbineEOWDateColumn,
    turbineCapacityColumn,
  ];
}

export function getBladeColumns(fields: TBladeFields, hasSavedViewsReleaseToggle: boolean) {
  return [
    bladeNameColumn,
    ...(hasSavedViewsReleaseToggle
      ? [
          {
            ...filterableSiteColumn,
            dataIndex: 'blade_site',
            sortKey: 'ancestorSiteName',
          },
          { ...componentTurbineColumn, dataIndex: 'blade_turbine' },
        ]
      : [bladeSiteColumn, bladeTurbineColumn]),
    assetStatusColumn(fields.assetStatusOptions),
    bladePositionColumn(fields.bladePositionOptions),
    omit(bladeMakeColumn, hasSavedViewsReleaseToggle ? '' : 'filterDropdown'),
    omit(bladeModelColumn, hasSavedViewsReleaseToggle ? '' : 'filterDropdown'),
    bladeSerialNumberVerifiedColumn(fields.serialNumberVerifiedOptions),
    bladeLengthColumn,
    bladeInstallDateColumn,
    bladeRemovedDateColumn,
    bladeAuxiliaryComponentsColumn,
  ];
}

function getAllTypesColumns() {
  return [
    assetColumn,
    assetTypeColumn,
    assetLocationColumn,
    assetParentColumn,
    assetParentTypeColumn,
  ];
}

export function generateUniqueIdentifierColumnForAsset(
  assetTypeData: AtlasGqlGetAssetFieldsForTypeQuery
): TTableColumnDef {
  const uniqueField = assetTypeData?.assetType?.fields?.find(
    field => field.isUniqueIdentifierField
  );

  if (uniqueField) {
    const name = uniqueField.name;
    return {
      key: uniqueField.searchKey,
      title: name,
      dataIndex: name,
      filterProperty: uniqueField.searchKey,
      sorter: sortBy(name),
    };
  }
  // Default column, we should never get here.
  return {
    key: 'key',
    title: 'title',
    dataIndex: 'title',
    filterProperty: '',
  };
}

// Function that can be used to dynamically generate columns based on the field definitions of an asset type.
// Ignore the unique identifer field, as that is generated elsewhere.
export function generateColumnsForAssetFields(
  assetTypeData: AtlasGqlGetAssetFieldsForTypeQuery
): TTableColumnDef[] {
  const fields =
    assetTypeData?.assetType?.fields?.filter(field => !field.isUniqueIdentifierField) || [];

  return fields.map(field => {
    const name = field.name;
    return {
      key: field.searchKey,
      title: name,
      dataIndex: name,
      filterProperty: field.searchKey,
      ...(name === 'Make'
        ? {
            filterDropdown: props => (
              <AssetAttributeSelectFilter
                fieldName="make"
                assetTypeName={assetTypeData?.assetType?.name}
                {...props}
              />
            ),
          }
        : {}),
      ...(name === 'Model'
        ? {
            filterDropdown: props => (
              <AssetAttributeSelectFilter
                fieldName="model"
                independentFieldFilterKey="make"
                assetTypeName={assetTypeData?.assetType?.name}
                {...props}
              />
            ),
          }
        : {}),
      sorter: sortBy(name),
    };
  });
}

/**
 * Function to fetch all the columns necessary for a given asset type
 * @param assetTypeName - Name of the asset type to get columns for
 * @param assetTypeFieldsData - Collection of field data to generate columns for. Can be undefined in the case of the AllAssets type.
 * @param safeToGenerateFieldData - Indicates that the query fetching the field data has completed with no errors, and it is safe to generate columns with the field data.
 * @param hasReleaseToggle - The function to check if a release toggle is enabled. (Usually from the useReleaseToggles hook)
 * @returns An array of Column definitions for the given asset type.
 */
export function getColumnsForAssetType(
  assetTypeName: string,
  assetTypeFieldsData: AtlasGqlGetAssetFieldsForTypeQuery | undefined,
  safeToGenerateFieldData: boolean,
  hasSavedViewsReleaseToggle: boolean
): TTableColumnDef[] {
  switch (assetTypeName) {
    case 'Site': {
      if (!safeToGenerateFieldData) break;
      return getSiteColumns(hasSavedViewsReleaseToggle);
    }
    case 'Turbine': {
      if (!assetTypeFieldsData || !safeToGenerateFieldData) break;
      const statusOptions = getOptionsForField('Status', assetTypeFieldsData);
      const fields: TTurbineFields = {
        statusOptions: statusOptions,
      };
      return getTurbineColumns(fields, hasSavedViewsReleaseToggle);
    }
    case 'Blade': {
      if (!assetTypeFieldsData || !safeToGenerateFieldData) break;
      const bladePositionOptions = getOptionsForField('Position', assetTypeFieldsData);
      const serialNumberVerifiedOptions = getOptionsForField(
        'Serial Number Verified',
        assetTypeFieldsData
      );
      const assetStatusOptions = getOptionsForField('Status', assetTypeFieldsData);
      const fields: TBladeFields = {
        bladePositionOptions,
        serialNumberVerifiedOptions,
        assetStatusOptions,
      };
      return getBladeColumns(fields, hasSavedViewsReleaseToggle);
    }
    // When rtv1:fleet_table_saved_views is cleaned up, this can be removed
    case allAssetsTypeName: {
      return getAllTypesColumns();
    }
    default: {
      if (safeToGenerateFieldData && assetTypeFieldsData) {
        const uniqueIdField = generateUniqueIdentifierColumnForAsset(assetTypeFieldsData);
        const fields = generateColumnsForAssetFields(assetTypeFieldsData);
        return [
          uniqueIdField,
          ...(hasSavedViewsReleaseToggle
            ? [
                {
                  ...filterableSiteColumn,
                  dataIndex: 'asset_site',
                  sortKey: 'ancestorSiteName',
                },
                componentTurbineColumn,
              ]
            : [assetSiteColumn, assetTurbineColumn]),
          ...fields,
        ];
      }
    }
  }
  // Shouldn't ever get here, but have a default fallback in case isSafe is false.
  return [assetColumn, assetTypeColumn];
}
