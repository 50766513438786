import styled from 'styled-components';
import { Breadcrumb as AntdBreadcrumb, Skeleton } from 'antd5';
import { Link } from 'react-router-dom';

export const Breadcrumb = styled(AntdBreadcrumb)`
  &.ant-breadcrumb {
    ol {
      li {
        &:not(:last-child) {
          padding-top: 2px;
        }

        &:has(.ant-skeleton) {
          padding-top: 2px;
        }

        :last-child {
          font-size: 1.2em;
        }
      }

      .ant-breadcrumb-separator {
        font-size: 1.05em;
        margin-top: 2px;
      }

      .ant-breadcrumb-link {
        a:hover {
          background-color: inherit;
          color: #44b6b8;
        }
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  font-size: 1.2em;
`;

export const StyledTitle = styled.span`
  font-size: 1.2em;
`;

export const StyledSkeleton = styled(Skeleton)`
  &.ant-skeleton {
    display: inline;
    vertical-align: text-top;
  }
  .ant-skeleton-content .ant-skeleton-title {
    margin: 0;
  }
`;
