import { FC, ReactElement, useState, MouseEvent } from 'react';
import { UsergroupAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Divider, Modal, ConfigProvider, SelectProps } from 'antd5';
import { AddCollabContainer, CollaboratorSelect } from './CollaboratorChooser.style';
import { useGetCollaboratorsQuery } from 'types/atlas-graphql';
import { notEmpty } from 'utils/types';
import { formatCollaborator } from './utils';

export interface CollaboratorChooserProps extends SelectProps<string[]> {
  // This prop somehow works but could not be found on downstream interface
  hidden?: boolean;
}

export const CollaboratorChooser: FC<CollaboratorChooserProps> = ({
  value,
  defaultValue,
  ...props
}) => {
  const { data, loading } = useGetCollaboratorsQuery();

  const collaborators = data?.collaborators?.filter(notEmpty) ?? [];

  const [addModalVisible, setAddModalVisible] = useState(false);
  const valueProps = value?.length ? { value } : { defaultValue };

  const handleAddClick = (_: MouseEvent) => {
    // small wait to let the antd dropdown animations get outta the way
    setTimeout(() => setAddModalVisible(true), 100);
  };

  const handleAddClose = () => {
    setAddModalVisible(false);
  };

  const emptyRender = () => (
    <div>
      <UsergroupAddOutlined style={{ marginRight: '4px' }} />
      <span>No current collaborators</span>
    </div>
  );

  // have to use onMouseDown rather than onClick cause antd
  const dropdownRender = (dropdown: ReactElement) => (
    <div id="collaborator-chooser-dropdown">
      <div onMouseDown={handleAddClick}>
        <AddCollabContainer>
          <PlusOutlined style={{ marginRight: '4px' }} />
          <span>Add Collaborator</span>
        </AddCollabContainer>
      </div>
      <Divider style={{ margin: '4px 0' }} />
      {dropdown}
    </div>
  );

  return (
    <ConfigProvider renderEmpty={emptyRender}>
      <Modal
        title={'Add a Collaborator'}
        open={addModalVisible}
        onCancel={handleAddClose}
        footer={null}
      >
        Please contact <a href="mailto:support@skyspecs.com">support@skyspecs.com</a> to link a new
        collaborator to your account. Once a collaborator relationship has been established, you
        will be able to grant that collaborator account access to whichever Work Orders you'd like.
      </Modal>
      <CollaboratorSelect
        mode="multiple"
        popupMatchSelectWidth={false}
        placeholder="Add Collaborators..."
        dropdownRender={dropdownRender}
        loading={loading}
        {...valueProps}
        {...props}
      >
        {collaborators.map(({ email, defaultOrganization }) => (
          <Select.Option key={email} value={email}>
            {formatCollaborator({ email, defaultOrganization })}
          </Select.Option>
        ))}
      </CollaboratorSelect>
    </ConfigProvider>
  );
};
